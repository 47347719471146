import { Button, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { handleKeyDown } from '../helpers/OnKeyDown';
import { isEmpty } from '../helpers/String';
import IFSCService from '../network/api/ifsc';
import BasicTextInput from './forms/BasicTextInput';

const useStyles = makeStyles(() => ({
	formCard: {
		padding: 24
	}
}));

const CheckIFSCForm = (props: any) => {
	const { setCurrentStep, setErrorMsg, setResult, setIfscNumber }: any = props || {};
	const classes = useStyles();
	const { t } = useTranslation();
	const ifscService = new IFSCService();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const validationSchema = yup.object({
		ifscNumber: yup
			.string()
			.min(11, (obj) => t('IFSC_INPUT_LENGTH', { len: obj.value.length }))
			.max(11, (obj) => t('IFSC_INPUT_LENGTH', { len: obj.value.length }))
			.required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			ifscNumber: ''
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { ifscNumber }: any = values || {};
				setIfscNumber(ifscNumber);
				const newToken = executeRecaptcha && (await executeRecaptcha('CheckIFSCform'));
				const result = await ifscService.getIFSCByCode(ifscNumber, newToken);
				const { data: resultData } = result;

				if (!isEmpty(resultData)) {
					setResult && setResult([resultData]);
					setCurrentStep && setCurrentStep(2);
				}
			} catch (err: any) {
				const { message, statusCode, status }: any = err?.response?.data || {};
				setErrorMsg && setErrorMsg(message);
				setCurrentStep && setCurrentStep(3);
				// eslint-disable-next-line no-console
				console.error('Error submiting CheckIFSCForm', err);
			}
		}
	});

	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={2} direction="column" justifyContent="center">
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
						<Grid item>
							<Typography variant="h3">{t('CHECK_IFSC_FORM_TITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('CHECK_IFSC_FORM_SUBTITLE')}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CHECK_IFSC_NAME"
						type="text"
						value={formik.values.ifscNumber}
						name="ifscnumber"
						placeholder={t('CHECK_IFSC_PH')}
						onChange={(e) => {
							formik.setFieldValue('ifscNumber', e.target.value);
						}}
						onKeyDown={(e) => {
							handleKeyDown(e, 'Enter', formik.handleSubmit);
						}}
						onBlur={formik.handleBlur}
						error={formik.touched.ifscNumber && Boolean(formik.errors.ifscNumber)}
						helperText={formik.errors.ifscNumber}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" fullWidth disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
						<Typography variant="button">{formik.isSubmitting ? t('SUBMITTING') : t('CHECK_IFSC_BUTTON')}</Typography>
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default CheckIFSCForm;
