import { FormHelperText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, memo } from 'react';

const useStyles = makeStyles<Theme, any>((theme) => ({
	container: {
		color: theme.palette.error.dark,
		backgroundColor: theme.palette.error.light,
		padding: 8,
		borderRadius: 8,
		marginTop: 8,
		position: 'relative',

		'&:before': {
			// layout
			content: '""',
			position: 'absolute',
			width: '0',
			height: '0',
			top: -8,
			left: '4px', // offset should move with padding of parent
			border: '.75rem solid transparent',
			borderTop: 'none',
			borderBottomColor: theme.palette.error.light
		}
	}
}));

const FormErrorHelperText = forwardRef((props: any, ref) => {
	const { helperText } = props || {};
	const classes = useStyles({});

	return (
		<>
			<FormHelperText className={classes.container}>{helperText}</FormHelperText>
		</>
	);
});

export default memo(FormErrorHelperText);
