import { Button, Card, Grid, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useStateSafe } from '../effects/useStateSafe';
import { isEmptyArray } from '../helpers/Array';
import { handleKeyDown } from '../helpers/OnKeyDown';
import { isEmpty } from '../helpers/String';
import { Bank, Branch, District, State } from '../interfaces/IFSC';
import IFSCService from '../network/api/ifsc';
import MultiSelectDropdown from './forms/MultiSelectDropdown';

const useStyles = makeStyles(() => ({
	formCard: {
		padding: 24
	}
}));

const FindIFSCForm = (props: any) => {
	const { setCurrentStep, setResult }: any = props || {};
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectedBank, setSelectedBank] = useStateSafe<string>('');
	const [selectedState, setSelectedState] = useStateSafe<string>('');
	const [selectedDistrict, setSelectedDistrict] = useStateSafe<string>('');
	const [bankList, setBankList] = useStateSafe<Array<Bank>>([]);
	const [stateList, setStateList] = useStateSafe<Array<State>>([]);
	const [districtList, setDistrictList] = useStateSafe<Array<District>>([]);
	const [branchList, setBranchList] = useStateSafe<Array<Branch>>([]);
	const ifscService = new IFSCService();
	const { executeRecaptcha } = useGoogleReCaptcha();

	useEffect(() => {
		const fetchBankList = async () => {
			try {
				const newToken = await executeRecaptcha('fetchBankList');
				const result = await ifscService.postIFSCFinder({}, newToken);

				if (result?.status === 200) {
					setBankList(result.data.map((x) => x?.bank));
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('fetchBankList error ', error);
			}
		};

		if (!executeRecaptcha) {
			// eslint-disable-next-line no-console
			console.error('Execute recaptcha not yet available');
			return;
		} else {
			fetchBankList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [executeRecaptcha]);

	useEffect(() => {
		if (!isEmpty(selectedBank) && !isEmpty(selectedState) && !isEmpty(selectedDistrict)) {
			fetchBranchList();
		} else if (!isEmpty(selectedBank) && !isEmpty(selectedState)) {
			fetchDistrictList();
		} else if (!isEmpty(selectedBank)) {
			fetchStateList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBank, selectedState, selectedDistrict]);

	const fetchStateList = async () => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('fetchStateList'));
			const result = await ifscService.postIFSCFinder({ bankName: selectedBank }, newToken);

			if (result?.status === 200) {
				setStateList(result.data.map((x) => x?.state));
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('fetchStateList error ', error);
		}
	};

	const fetchDistrictList = async () => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('fetchDistrictList'));
			const result = await ifscService.postIFSCFinder({ bankName: selectedBank, state: selectedState }, newToken);

			if (result?.status === 200) {
				setDistrictList(result.data.map((x) => x?.district));
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('fetchDistrictList error ', error);
		}
	};

	const fetchBranchList = async () => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('fetchBranchList'));
			const result = await ifscService.postIFSCFinder({ bankName: selectedBank, state: selectedState, district: selectedDistrict }, newToken);

			if (result?.status === 200) {
				setBranchList(result.data.map((x) => x?.branch));
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('fetchBranchList error ', error);
		}
	};

	const validationSchema = yup.object({
		bankName: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED')),
		state: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED')),
		district: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED')),
		branch: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			bankName: '',
			state: '',
			district: '',
			branch: ''
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { bankName, state, district, branch }: any = values || {};
				const newToken = executeRecaptcha && (await executeRecaptcha('findIfscForm'));
				const result = await ifscService.postIFSCFinder({ bankName, state, district, branch }, newToken);
				const { data: resultData } = result;
				setResult && setResult(resultData);
				setCurrentStep && setCurrentStep(2);
				window.scrollTo(0, 0);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Error submiting FindIFSCForm', e);
			}
		}
	});

	const resetFields = (step: number) => {
		switch (step) {
			case 1: {
				formik.setFieldValue('state', '');
				formik.setFieldValue('district', '');
				formik.setFieldValue('branch', '');
				setSelectedState('');
				setSelectedDistrict('');
				formik.touched.state = false;
				formik.touched.district = false;
				formik.touched.branch = false;
				break;
			}
			case 2: {
				formik.setFieldValue('district', '');
				formik.setFieldValue('branch', '');
				setSelectedDistrict('');
				formik.touched.district = false;
				formik.touched.branch = false;
				break;
			}
			case 3: {
				formik.setFieldValue('branch', '');
				formik.touched.branch = false;
				break;
			}
		}
	};
	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={2} direction="column" justifyContent="center">
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
						<Grid item>
							<Typography variant="h3">{t('FIND_IFSC_FORM_TITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('FIND_IFSC_FORM_SUBTITLE')}</Typography>
						</Grid>
						{isEmptyArray(bankList) && <LinearProgress style={{ width: '95%' }} />}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<MultiSelectDropdown
						options={bankList}
						value={formik.values.bankName}
						placeholder={t('FIND_IFSC_BANK_PH')}
						onChange={(event, values) => {
							if (!isEmpty(formik.values.bankName)) {
								resetFields(1);
							}
							formik.setFieldValue('bankName', values);
							setSelectedBank(values);
						}}
						error={formik.touched.bankName && Boolean(formik.errors.bankName)}
						helperText={formik.errors.bankName}
						disabled={isEmptyArray(bankList)}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultiSelectDropdown
						options={stateList}
						value={formik.values.state}
						placeholder={t('FIND_IFSC_STATE_PH')}
						onChange={(event, values) => {
							if (!isEmpty(formik.values.state)) {
								resetFields(2);
							}
							formik.setFieldValue('state', values);
							setSelectedState(values);
						}}
						error={formik.touched.state && Boolean(formik.errors.state) && !isEmptyArray(stateList) && !isEmpty(formik.values.bankName)}
						helperText={formik.errors.state}
						disabled={isEmptyArray(stateList) || isEmpty(formik.values.bankName)}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultiSelectDropdown
						options={districtList}
						value={formik.values.district}
						placeholder={t('FIND_IFSC_DISTRICT_PH')}
						onChange={(event, values) => {
							if (!isEmpty(formik.values.district)) {
								resetFields(3);
							}
							formik.setFieldValue('district', values);
							setSelectedDistrict(values);
						}}
						error={formik.touched.district && Boolean(formik.errors.district) && !isEmptyArray(districtList) && !isEmpty(formik.values.state)}
						helperText={formik.errors.district}
						disabled={isEmptyArray(districtList) || isEmpty(formik.values.state)}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultiSelectDropdown
						options={branchList}
						value={formik.values.branch}
						placeholder={t('FIND_IFSC_BRANCH_PH')}
						onChange={(event, values) => {
							formik.setFieldValue('branch', values);
						}}
						error={formik.touched.branch && Boolean(formik.errors.branch) && !isEmptyArray(branchList) && !isEmpty(formik.values.district)}
						helperText={formik.errors.branch}
						disabled={isEmptyArray(branchList) || isEmpty(formik.values.district)}
						onKeyDown={(e) => {
							handleKeyDown(e, 'Enter', formik.handleSubmit);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" disableRipple fullWidth disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
						{formik.isSubmitting ? t('SUBMITTING') : t('CHECK_IFSC_BUTTON')}
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default FindIFSCForm;
