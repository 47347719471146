/* eslint-disable max-len */
const insurance = {
	INSURANCE_MAIN_TITLE: '为您的员工和资产获得优厚的福利和全面的保护。',

	INSURANCE_EMP_BEN_TITLE: '员工福利',
	INSURANCE_EMP_BEN_SUBTITLE: `保护贵公司宝贵的资产`,
	INSURANCE_EMP_BEN_DESC: `可获得住院保险和特定的门诊治疗以及医疗卡\n（可在特定诊所使用）\n此涵盖死亡和部分/完全永久残疾保障。`,
	INSURANCE_EMP_BEN_HIGHLIGHT: `"可获得高达 RM1,000,000 的保障"`,

	INSURANCE_FIRE_TITLE: '火灾保险',
	INSURANCE_FIRE_SUBTITLE: '保护（您的）资产免受火灾所造成的伤害和损失',
	INSURANCE_FIRE_DESC: `可获得其他灾害的保险\n（例如洪水或撞击造成的损坏）`,
	INSURANCE_FIRE_HIGHLIGHT: '"免去一切后顾之忧"',

	INSURANCE_BURGLAR_TITLE: '盗窃保险',
	INSURANCE_BURGLAR_SUBTITLE: `保护您的企业免受盗窃事件所造成的损失`,
	INSURANCE_BURGLAR_DESC: `如果您的财产及其资产因暴力侵入、劫持或持械抢劫而被盗，您将获得保障资格。`,
	INSURANCE_BURGLAR_HIGHLIGHT: `"最高可获全额保障"`,

	INSURANCE_OTHERS_TITLE: '其他保险',
	INSURANCE_OTHERS_SUBTITLE: '为您打造的更多保障',
	INSURANCE_OTHERS_DESC: `您还有其他想要保护的资产吗？满足您一切业务需求 - 海上保险、电子设备保险，机械保险。`,
	INSURANCE_OTHERS_HIGHLIGHT: `"我们为您提供保障"`,

	INSURANCE_FOOTER_TITLE: '提供支持',
	INSURANCE_FOOTER_DESC: `MoneyMatch 保险是与 MM Tech（Money Match Sdn Bhd 的全资子公司）以及我们的合作机构合作提供。Onward & Upwards Consulting Sdn Bhd 获得 Allianz for Life Insurance (BUSS1) 的许可，Peri Risk Sdn Bhd 获得 Allianz for General Insurance (KL39825) 的许可。`,

	INSURANCE_TYPE_EMPLOYEE: 'Employee Benefits',
	INSURANCE_TYPE_FIRE: 'Fire Insurance',
	INSURANCE_TYPE_BURGLARY: 'Burglary Insurance',
	INSURANCE_TYPE_MARITIME: 'Maritime Insurance',
	INSURANCE_TYPE_PUBLIC_LIABILITY: 'Public Liability Insurance',
	INSURANCE_TYPE_OTHERS: 'Others',

	INSURANCE_PROVIDER_GE: 'Great Eastern',
	INSURANCE_EMPLOYEE_SUB_CON: 'Conventional',
	INSURANCE_EMPLOYEE_SUB_TAKAFUL: 'Takaful',

	QUOTATION_MAIN_HEADER: 'Get a Quote',
	QUOTATION_MAIN_SUB_HEADER: 'Help us with the details below!',

	TAB_TYPE_OF_INSURANCE: 'Type of Insurance',
	TAB_APPLICATION_DETAILS: 'Application Details',
	TAB_ADD_ONS: 'Add Ons',
	TAB_CONTACT_DETAILS: 'Contact Details',

	WHAT_TO_PROTECT_LBL: 'What would you like to protect today?',

	INSURANCE_TYPE_DESC_EMP_BEN_TITLE: 'Your Employees',
	INSURANCE_TYPE_DESC_EMP_BEN_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_FIRE_TITLE: 'Your Premises against Fire',
	INSURANCE_TYPE_DESC_FIRE_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_BURGLAR_TITLE: 'Your Physical Assets against Theft',
	INSURANCE_TYPE_DESC_BURGLAR_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_MARINE_CARGO_TITLE: 'Your Marine Cargo',
	INSURANCE_TYPE_DESC_MARINE_CARGO_DESC:
		'Protect your goods shipped against losses or damages during the transit, from the time your goods leave the premises of the seller until the time they arrive at the buyer’s destination.',
	INSURANCE_TYPE_DESC_MARINE_HULL_TITLE: 'Your Marine Hull',
	INSURANCE_TYPE_DESC_MARINE_HULL_DESC: 'Protect your ships and vessels against losses or damages during operations.',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_TITLE: 'Your Day-to-Day Operations',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_DESC:
		'Protect your business against legal liabilities to a 3rd party as a result of an accident happening in connection with your business operations.',
	INSURANCE_TYPE_DESC_OTHERS_TITLE: 'Others',
	INSURANCE_TYPE_DESC_OTHERS_DESC:
		'More coverages made just for you.  Something special or unique you would like to get insured? No worries, we’ve got you covered.',

	INSURANCE_STEP_APP_BUSINESS_DETAILS_LBL: 'Business Details',
	INSURANCE_STEP_APP_BUSINESS_NATURE_QUES: 'What is your business nature?'
};

export default insurance;
