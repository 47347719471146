import { REGION } from '../constant/locale';

export enum API {
	IFSC = 'IFSC',
	INS = 'INS'
}

export function getBaseUrl(code: API): string {
	switch (code) {
		case API.INS:
			return process.env.REACT_APP_URL_INS;
		case API.IFSC:
			return process.env.REACT_APP_URL_IFSC;
		default:
			return code;
	}
}
