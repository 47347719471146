import GetNewCTA from './GetNewCTA';
import IFSCCheckResultForm from './IFSCCheckResultForm';
import SplitScreen from './SplitScreen';

const IFSCResult = (props) => {
	const { setCurrentStep, ifscNumber, result }: any = props || {};

	return (
		<SplitScreen spacing={3} weight={8}>
			<IFSCCheckResultForm setCurrentStep={setCurrentStep} ifscNumber={ifscNumber} result={result} />
			<GetNewCTA />
		</SplitScreen>
	);
};

export default IFSCResult;
