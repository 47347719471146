import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
	subtitle: (props: any) => ({
		color: '#9B9A97',
		...props.subtitle
	}),
	content: (props: any) => ({
		color: '#333333',
		...props.content
	}),
	container: (props: any) => ({
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		gap: 8,
		...props.container
	})
}));

const DetailsGridColumns = (props) => {
	const { data, customStyle, isLoading } = props;
	const classes = useStyles(customStyle);

	return (
		data &&
		data.map((item, index) => {
			const { subTitle, content, subtitleVariant, subtitleClassName, contentVariant, contentClassName } = item;

			return (
				<Grid item key={`${subTitle}-${index}`} className={classes.container}>
					{isLoading ? (
						<Skeleton />
					) : (
						<>
							<Typography variant={subtitleVariant || 'body2'} className={subtitleClassName || classes.subtitle}>
								{subTitle}
							</Typography>
							<Typography component="div" variant={contentVariant || 'body1'} className={contentClassName || classes.content}>
								{content}
							</Typography>
						</>
					)}
				</Grid>
			);
		})
	);
};

export default DetailsGridColumns;
