/* eslint-disable max-len */
const insurance = {
	INSURANCE_MAIN_TITLE: 'Dapatkan faedah yang terbaik dan perlindungan komprehensif <0/>untuk para pekerja dan aset perniagaan anda.',

	INSURANCE_EMP_BEN_TITLE: 'Manfaat pekerja',
	INSURANCE_EMP_BEN_SUBTITLE: `Lindungi aset syarikat anda yang paling berharga`,
	INSURANCE_EMP_BEN_DESC: `Dapatkan perlindungan kemasukan hospital dan beberapa rawatan pesakit luar yang terpilih atau dapatkan kad perubatan untuk rawatan di klinik panel berdaftar untuk para pekerja anda. Selain itu, anda juga boleh mendapatkan perlindungan sekiranya berlaku kejadian malang seperti kematian, hilang upaya menyeluruh atau hilang upaya separa dan kekal. `,
	INSURANCE_EMP_BEN_HIGHLIGHT: `"Dapatkan perlindungan sehingga RM1,000,000"`,

	INSURANCE_FIRE_TITLE: 'Insurans Kebakaran',
	INSURANCE_FIRE_SUBTITLE: 'Dapatkan perlindungan daripada kerugian atau kerosakan harta benda akibat kebakaran',
	INSURANCE_FIRE_DESC:
		'Kami juga menyediakan perlindungan bagi perniagaan anda daripada kerugian susulan daripada kerosakan akibat bencana seperti banjir dan lain-lain.',
	INSURANCE_FIRE_HIGHLIGHT: '"Tidak perlu bimbang lagi"',

	INSURANCE_BURGLAR_TITLE: 'Insurans Kecurian',
	INSURANCE_BURGLAR_SUBTITLE: `Dapatkan perlindungan daripada kerugian akibat kecurian harta benda milik perniagaan anda.`,
	INSURANCE_BURGLAR_DESC: `Dapatkan perlindungan sekiranya kehilangan harta akibat daripada rompakan dengan menggunakan paksaan dan kekerasan, kemasukan ke dalam premis dengan menggunakan keganasan dan rompakan bersenjata.`,
	INSURANCE_BURGLAR_HIGHLIGHT: `"Pelan perlindungan yang tinggi"`,

	INSURANCE_OTHERS_TITLE: 'Insurans lain',
	INSURANCE_OTHERS_SUBTITLE: 'Pelbagai pelan perlindungan disediakan khas untuk anda.',
	INSURANCE_OTHERS_DESC: `Anda perlukan perlindungan bagi aset perniagaan yang lain? Dari insurans marin, insurans peralatan elektronik dan juga insurans mesin jentera - pelbagai perlindungan yang kami sediakan untuk keperluan perniagaan anda.`,
	INSURANCE_OTHERS_HIGHLIGHT: `"Kami sedia membantu melindungi anda"`,

	INSURANCE_FOOTER_TITLE: 'Dijana oleh',
	INSURANCE_FOOTER_DESC: `Insurans yang ditawarkan oleh MoneyMatch kepada anda adalah kerjasama antara MM Tech (anak syarikat milik penuh Money Match Sdn Bhd) dengan agensi rakan kongsi kami. Onward & Upwards Consulting Sdn Bhd berlesen di bawah Allianz for Life Insurance (BUSS1) dan Peri Risk Sdn Bhd berlesen di bawah Allianz for General Insurance (KL39825).`,

	INSURANCE_TYPE_EMPLOYEE: 'Employee Benefits',
	INSURANCE_TYPE_FIRE: 'Fire Insurance',
	INSURANCE_TYPE_BURGLARY: 'Burglary Insurance',
	INSURANCE_TYPE_MARITIME: 'Maritime Insurance',
	INSURANCE_TYPE_PUBLIC_LIABILITY: 'Public Liability Insurance',
	INSURANCE_TYPE_OTHERS: 'Others',

	INSURANCE_PROVIDER_GE: 'Great Eastern',
	INSURANCE_EMPLOYEE_SUB_CON: 'Conventional',
	INSURANCE_EMPLOYEE_SUB_TAKAFUL: 'Takaful',

	QUOTATION_MAIN_HEADER: 'Get a Quote',
	QUOTATION_MAIN_SUB_HEADER: 'Help us with the details below!',

	TAB_TYPE_OF_INSURANCE: 'Type of Insurance',
	TAB_APPLICATION_DETAILS: 'Application Details',
	TAB_ADD_ONS: 'Add Ons',
	TAB_CONTACT_DETAILS: 'Contact Details',

	WHAT_TO_PROTECT_LBL: 'What would you like to protect today?',

	INSURANCE_TYPE_DESC_EMP_BEN_TITLE: 'Your Employees',
	INSURANCE_TYPE_DESC_EMP_BEN_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_FIRE_TITLE: 'Your Premises against Fire',
	INSURANCE_TYPE_DESC_FIRE_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_BURGLAR_TITLE: 'Your Physical Assets against Theft',
	INSURANCE_TYPE_DESC_BURGLAR_DESC: `Obtain coverage in case your property and its contents are stolen through actual forcible or violent entry, theft by hold-up or armed robbery.`,
	INSURANCE_TYPE_DESC_MARINE_CARGO_TITLE: 'Your Marine Cargo',
	INSURANCE_TYPE_DESC_MARINE_CARGO_DESC:
		'Protect your goods shipped against losses or damages during the transit, from the time your goods leave the premises of the seller until the time they arrive at the buyer’s destination.',
	INSURANCE_TYPE_DESC_MARINE_HULL_TITLE: 'Your Marine Hull',
	INSURANCE_TYPE_DESC_MARINE_HULL_DESC: 'Protect your ships and vessels against losses or damages during operations.',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_TITLE: 'Your Day-to-Day Operations',
	INSURANCE_TYPE_DESC_PUBLIC_LIABILITY_DESC:
		'Protect your business against legal liabilities to a 3rd party as a result of an accident happening in connection with your business operations.',
	INSURANCE_TYPE_DESC_OTHERS_TITLE: 'Others',
	INSURANCE_TYPE_DESC_OTHERS_DESC:
		'More coverages made just for you.  Something special or unique you would like to get insured? No worries, we’ve got you covered.',

	INSURANCE_STEP_APP_BUSINESS_DETAILS_LBL: 'Business Details',
	INSURANCE_STEP_APP_BUSINESS_NATURE_QUES: 'What is your business nature?'
};

export default insurance;
