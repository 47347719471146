import { Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import SplitScreen from './SplitScreen';

const useStyles = makeStyles((theme) => ({
	container: {
		margin: 32
	},
	card: {
		width: '100%',
		marginTop: 24,
		padding: 24
	},
	ifscHeader: {
		textAlign: 'center'
	},
	ifscDesc: {
		textAlign: 'center'
	}
}));

const CardExample = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Card className={classes.card}>
			<SplitScreen direction="column" weight={12}>
				<Typography variant="h5">{t('IFSC_EXAMPLE_HEADER')}</Typography>

				<Grid container justifyContent="center" spacing={3}>
					<Grid item>
						<SplitScreen direction="column" weight={12}>
							<Typography variant="h3">{t('BANK_CODE_EXAMPLE')}</Typography>
							<Typography variant="body2">{t('BANK_CODE')}</Typography>
						</SplitScreen>
					</Grid>
					<Grid item>
						<SplitScreen direction="column" weight={12}>
							<Typography variant="h3">{t('CHECK_DIGITS_EXAMPLE')}</Typography>
							<Typography variant="body2">{t('CHECK_DIGITS')}</Typography>
						</SplitScreen>
					</Grid>
					<Grid item>
						<SplitScreen direction="column" weight={12}>
							<Typography variant="h3">{t('BRANCH_CODE_EXAMPLE')}</Typography>
							<Typography variant="body2">{t('BRANCH_CODE')}</Typography>
						</SplitScreen>
					</Grid>
				</Grid>
			</SplitScreen>
		</Card>
	);
};

const WhatIsIFSC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<SplitScreen direction="row" weight={12}>
			<Typography variant="h3" className={classes.ifscHeader}>
				{t('WHAT_IS_IFSC')}
			</Typography>
			<>
				<Typography variant="h5" className={classes.ifscDesc}>
					{t('WHAT_IS_IFSC_DESC')}
				</Typography>
				<CardExample />
			</>
		</SplitScreen>
	);
};

export default WhatIsIFSC;
