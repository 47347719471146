import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmDetailsSVG } from '../../assets/svg/infos/confimDetails.svg';
import useScreenViews from '../../effects/useScreenViews';
import SplitScreen from '../SplitScreen';

const Desc = () => {
	const { t } = useTranslation();

	return (
		<Grid container direction="column" justifyContent="center">
			<Grid item>
				<Typography variant="h3">{t('ALWAYS_CONFIRM_DETAILS')}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">
					<Trans i18nKey="ALWAYS_CONFIRM_DETAILS_DESC" components={[<br />]} />
				</Typography>
			</Grid>
		</Grid>
	);
};

const AlwaysConfirm = () => {
	const { belowTabletView } = useScreenViews();
	const ContentArr = [<Desc key="AlwaysConfirmdesc" />, <ConfirmDetailsSVG key="AlwaysConfirmsvg" />];
	const orderedChildren = belowTabletView ? [...ContentArr]?.reverse() : ContentArr;
	return <SplitScreen>{orderedChildren}</SplitScreen>;
};

export default AlwaysConfirm;
