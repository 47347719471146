export const FOOTER_PERSONAL = [
	{ title: 'Bank Transfers', link: 'personalTransfer' },
	{ title: 'Mobile Wallets Transfers', link: 'personalTransfer', propsLink: '#platform' },
	{ title: 'Cash Pickup', link: 'personalTransfer' },
	{ title: 'Insurance', link: 'insuranceLink' },
	{ title: 'Referral Program', link: 'referralProgramLink' }
];

export const FOOTER_BUSINESS = [
	{ title: 'Business' },
	{ title: 'Transfer', link: 'businessTransfer' },
	{ title: 'Insurance', link: 'insuranceLink' }
];

export const FOOTER_ENTERPRISE = [
	{ title: 'Enterprise' },
	{ title: 'Payment Network', link: 'enterpriseLink' },
	{ title: 'Become an Agent', link: 'agentsLink' }
];

export const FOOTER_RESOURCES = [
	{ title: 'Resources' },
	{ title: 'API Reference', link: 'apiRefLink' },
	{ title: 'BEFTN Routing Number', link: 'BEFTNLink' },
	{ title: 'IFSC Codes', link: 'IFSCLink' }
	// { title: 'Coverage', link: '' } TO DO: INCLUDE ONCE PAGE IS DONE
];

export const FOOTER_COMPANY = [
	{ title: 'Company' },
	{ title: 'About Us', link: 'aboutLink' },
	{ title: 'Careers', link: 'careersLink' },
	{ title: 'News and Media', link: 'newsLink' },
	{ title: 'Blog', link: 'blogLink' },
	{ title: 'Contact Us', link: 'contactUsLink' }
];

export const FOOTER_LEGAL = [
	{ title: 'Legal' },
	{ title: 'Terms of Service', link: 'privacyLink' },
	{ title: 'Privacy Policy', link: 'privacyLink' }
];

export const SOCIAL_MEDIA_ICON_LINKS = [
	{
		img: 'https://assets.moneymatch.co/images/linkedin_logo.png',
		link: 'https://www.linkedin.com/company/moneymatchapp/'
	},
	{
		img: 'https://assets.moneymatch.co/images/facebook_logo.png',
		link: 'https://www.facebook.com/moneymatchapp/'
	},
	{
		img: 'https://assets.moneymatch.co/images/twitter_logo.png',
		link: 'https://twitter.com/moneymatchapp'
	},
	{
		img: 'https://assets.moneymatch.co/images/instagram_logo.png',
		link: 'https://www.instagram.com/moneymatchapp/'
	}
];
