/* eslint-disable max-len */
const common = {
	SELECT_LANGUAGE: 'Sila pilih bahasa',
	ENGLISH: 'English',
	CHINESE: '中文',
	MALAY: 'Bahasa Malaysia',
	WELCOME_MSG: 'Selamat Datang, {{userName}}!',
	WELCOME_BACK_MSG: 'Selamat Kembali, {{userName}}!',
	FEATURE_DASHBOARD: 'Papan Pemuka',
	FEATURE_BULK_UPLOAD: 'Muat Naik Transaksi',
	FEATURE_TRANS: 'Buat Penghantaran Baru',
	FEATURE_DIRECT_TRANS: 'Penghantaran Baru',
	FEATURE_ACCOUNT: 'Pengurusan Akaun',
	FEATURE_ACTIVITIES: 'Aktiviti',
	FEATURE_INSURANCE: 'Insurans',
	FEATURE_FINANCING: 'Pembiayaan',
	FEATURE_REQ_FOR_PRICE: 'Request For Price',
	RECORDS_PER_PAGE_LBL: 'rekod setiap halaman',
	PREVIOUS: 'Sebelum',
	NEXT: 'Seterusnya',
	SEARCH_PLACEHOLDER: 'Cari',
	APPLY_LBL: 'Gunakan',
	REMOVE_LBL: 'Remove',
	SAVE_LBL: 'Simpan',
	PROCEED_LBL: 'Teruskan',
	CANCEL_LBL: 'Batalkan',
	SUBMIT_LBL: 'Hantar',
	LOADING_LBL: 'Loading',
	VALIDATE_LBL: 'Validate',
	SUBMIT_ORDER_LBL: 'Hantar Pesanan',
	CONTINUE_LBL: 'Teruskan',
	CLOSE_LBL: 'Tutup',
	REQUIRED: 'required*',
	BACK_LBL: 'Kembali',
	LOG_OUT_LBL: 'Log Keluar',
	STAY_ACTIVE_LBL: 'Kekal Aktif',
	DROP_DOWN_PLACEHOLDER: '- Sila pilih satu -',
	BACK_TO_DASH_LBL: 'Kembali ke Dashboard',
	YES: 'Ya',
	NO: 'Tidak',
	'Manual Deposit': 'Muat Naik Resit Pembayaran',
	FOOTER_CONTACT_ACC_MANAGER: `<0/><0/>Anda berminat? Hubungi Pengurus Perhubungan Korporat anda atau <1>hubungi Pegawai Komunikasi di talian hotline kami +603-30993889</1> untuk ketahui lebih lanjut lagi. `,
	LOGIN_SESSION_EXPIRING_TITLE: `Sesi Log Masuk Akan Tamat Tempoh!`,
	LOGIN_SESSION_EXPIRING_CONTENT: `Kami sedang berusaha untuk memastikan anda selamat dan sentiasa berhubung.\nAnda akan dilog keluar dalam masa 10 saat.\nSila log masuk semula untuk kekal aktif.`
};

export default common;
