import { useTheme, useMediaQuery } from '@mui/material';

const useScreenViews = () => {
	const theme = useTheme();

	const aboveXSDeviceView = useMediaQuery(theme.breakpoints.up('xs'));
	const belowXSDeviceView = useMediaQuery(theme.breakpoints.down('xs'));
	const belowMobileView = useMediaQuery(theme.breakpoints.down('sm'));
	const aboveMobileView = useMediaQuery(theme.breakpoints.up('sm'));
	const belowTabletView = useMediaQuery(theme.breakpoints.down('md'));
	const aboveTabletView = useMediaQuery(theme.breakpoints.up('md'));
	const belowDesktopView = useMediaQuery(theme.breakpoints.down('lg'));
	const aboveDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

	return {
		belowDesktopView,
		aboveDesktopView,
		belowTabletView,
		aboveTabletView,
		belowMobileView,
		aboveMobileView,
		aboveXSDeviceView,
		belowXSDeviceView
	};
};

export default useScreenViews;
