import autoBind from 'auto-bind';
import { AxiosInstance } from 'axios';
import { API } from '../baseUrl';
import create from '../common.http';

export default class IFSCService {
	private ifscApi: AxiosInstance;

	constructor() {
		this.ifscApi = create(API.IFSC);
		autoBind(this);
	}

	public async postIFSCFinder(req: any, token: string): Promise<any> {
		const response = await this.ifscApi.post(`/web/ifsc/finder`, req, { headers: { 'x-captcha-token': token } });
		return response;
	}

	public async getIFSCByCode(code: string, token: string): Promise<any> {
		const response = await this.ifscApi.get(`/web/ifsc/${code}`, { headers: { 'x-captcha-token': token } });
		return response;
	}
}
