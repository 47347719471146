export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

export const deepEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}

	return true;
};

export const isObject = (object) => {
	return object != null && typeof object === 'object';
};

export const reduceObjectByKey = (object, matchedKey) => {
	const reducedObject = {};

	for (const [key, value] of Object.entries(object)) {
		if (!key.includes(matchedKey)) {
			reducedObject[key] = value;
		}
	}
	return reducedObject;
};
