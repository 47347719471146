import common from './common';
import insurance from './insurance';
import landing from './landing';

const all = {
	...insurance,
	...common,
	...landing
};

export default all;
