const common = {
	SELECT_LANGUAGE: '请选择语言',
	ENGLISH: 'English',
	CHINESE: '中文',
	MALAY: 'Bahasa Malaysia',
	WELCOME_MSG: '{{userName}}, 欢迎光临 !',
	WELCOME_BACK_MSG: '{{userName}}, 欢迎再次光临!',
	FEATURE_DASHBOARD: '主页',
	FEATURE_BULK_UPLOAD: '上载新的汇款',
	FEATURE_TRANS: '创建新的汇款',
	FEATURE_DIRECT_TRANS: '创建新的汇款',
	FEATURE_ACCOUNT: '账户管理',
	FEATURE_ACTIVITIES: '汇款交易',
	FEATURE_INSURANCE: '保险',
	FEATURE_FINANCING: '融资',
	FEATURE_REQ_FOR_PRICE: 'Request For Price',
	RECORDS_PER_PAGE_LBL: '每页记录',
	PREVIOUS: '上一页',
	NEXT: '下一页',
	SEARCH_PLACEHOLDER: '搜索',
	APPLY_LBL: '使用',
	REMOVE_LBL: 'Remove',
	SAVE_LBL: '保存',
	PROCEED_LBL: '继续',
	CANCEL_LBL: '取消',
	SUBMIT_LBL: '提交',
	LOADING_LBL: 'Loading',
	VALIDATE_LBL: 'Validate',
	SUBMIT_ORDER_LBL: '提交订单',
	CONTINUE_LBL: '继续',
	CLOSE_LBL: '关闭',
	REQUIRED: 'required*',
	BACK_LBL: '返回',
	LOG_OUT_LBL: '登出',
	STAY_ACTIVE_LBL: '保持活跃',
	DROP_DOWN_PLACEHOLDER: '- 请选择 -',
	BACK_TO_DASH_LBL: '返回仪表板',
	YES: '是的',
	NO: '不',
	'Manual Deposit': '上载付款收据',
	FOOTER_CONTACT_ACC_MANAGER: `<0/><0/>欲了解更多详情，请致电 <1>+603-30993889 与我们的专员洽谈，或联系您的企业经理。</1>`,
	LOGIN_SESSION_EXPIRING_TITLE: `登录会话即将结束！`,
	LOGIN_SESSION_EXPIRING_CONTENT: `我们正在努力确保您的安全和连接。\n您将在 10 秒后退出登录。\n请重新登录以保持活跃。`
};

export default common;
