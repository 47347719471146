import { Grid, GridDirection, GridSize, GridSpacing } from '@mui/material';
import useScreenViews from '../effects/useScreenViews';

type GridJustification = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';

const SplitScreen = (props: SplitScreenProps) => {
	const { children, weight = 6, direction = 'row', spacing = 0, leftJustify = 'center', rightJustify = 'center', className } = props;
	const rightWeight = Number(weight === 12 ? 12 : 12 - Number(weight)) as GridSize;
	const { belowTabletView } = useScreenViews();

	return (
		<Grid container spacing={spacing} direction={belowTabletView ? 'column' : direction} className={className}>
			<Grid item container xs={belowTabletView ? 12 : weight} justifyContent={leftJustify}>
				{children && children[0]}
			</Grid>
			<Grid item container xs={belowTabletView ? 12 : rightWeight} justifyContent={rightJustify}>
				{children && children[1]}
			</Grid>
		</Grid>
	);
};

interface SplitScreenProps {
	children: React.ReactNode;
	weight?: GridSize;
	direction?: GridDirection;
	spacing?: GridSpacing;
	leftJustify?: GridJustification;
	rightJustify?: GridJustification;
	className?: string;
}
export default SplitScreen;
