import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { DEFAULT_REGION, MMT_REGION_NAME, SUPPORTED_REGION } from '@constant/region';
import { useStateSafe } from './useStateSafe';

export const useVisitedRegion = () => {
	const [countryNameToDisplay, setCountryNameToDisplay] = useStateSafe('');
	const [visitedCountryCode, setVisitedCountryCode] = useStateSafe('');
	const location = useLocation();

	useEffect(() => {
		checkVisitedRegion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const checkVisitedRegion = () => {
		const browserPathNames = location.pathname.split('/');
		const firstPathName = browserPathNames[1]?.toUpperCase();
		const supportedRegion = SUPPORTED_REGION.find((x) => x.countryCode === firstPathName && x.isActive);
		const currentCountryCode = supportedRegion?.countryCode || DEFAULT_REGION;

		setVisitedCountryCode(currentCountryCode);
		setCountryNameToDisplay(MMT_REGION_NAME[currentCountryCode]);
	};

	return {
		countryNameToDisplay,
		visitedCountryCode
	};
};
