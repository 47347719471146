import { Grid } from '@mui/material';
import CheckIFSCForm from './CheckIFSCForm';
import FindIFSCForm from './FindIFSCForm';

const IFSCFinder = (props: any) => {
	const { setCurrentStep, setIfscNumber, setErrorMsg, setResult, ifscNumber }: any = props || {};

	return (
		<Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
			<Grid item xs={12} md={6}>
				<CheckIFSCForm
					ifscNumber={ifscNumber}
					setCurrentStep={setCurrentStep}
					setIfscNumber={setIfscNumber}
					setErrorMsg={setErrorMsg}
					setResult={setResult}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<FindIFSCForm setCurrentStep={setCurrentStep} setIfscNumber={setIfscNumber} setResult={setResult} />
			</Grid>
		</Grid>
	);
};

export default IFSCFinder;
