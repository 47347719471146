import { FormHelperText, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrorHelperText from './FormErrorHelperText';

const useStyles = makeStyles<Theme, any>(() => ({
	textInput: {
		width: '100%',
		color: '#616161',
		borderColor: '#616161',
		'& input': {
			height: 28,
			borderRadius: 8,
			padding: '14px 8px',
			color: '#616161'
		},
		'& label': {
			color: '#616161'
		},
		'& .Mui-focused': {
			color: '#616161',
			borderColor: '#616161'
		},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: '#616161'
			}
		}
	}
}));

const BasicTextInput = forwardRef((props: any, ref) => {
	const { label, value, onChange, error, helperText, showHelperText, helperTextStyle, ...rest } = props || {};
	const classes = useStyles({});
	const { t } = useTranslation();

	return (
		<>
			<TextField
				inputRef={ref}
				label={t(label)}
				className={classes.textInput}
				{...rest}
				variant="outlined"
				value={value}
				onChange={onChange}
				autoFocus
			/>
			{showHelperText && <FormHelperText style={helperTextStyle || { textAlignLast: 'right', margin: 0 }}>{helperText}</FormHelperText>}
			{error && <FormErrorHelperText helperText={helperText} />}
		</>
	);
});

export default memo(BasicTextInput);
