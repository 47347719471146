import { Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { PATHS_BY_ENV } from '../constant/paths';
import { env } from '../helpers/EnvHelper';

const useStyles = makeStyles((theme) => ({
	toolContainer: {
		[theme.breakpoints.down('md')]: {
			marginTop: '64px'
		}
	},
	toolNavItem: {
		width: '90%',
		[theme.breakpoints.up('lg')]: {
			width: '70%'
		},
		margin: '16px 0px 0px 0px',
		'& >.MuiGrid-item': {
			marginRight: 28
		}
	},
	boldText: {
		fontWeight: 'bold'
	},
	link: {
		textDecoration: 'none',
		color: 'black',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

const ToolNav = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	// TODO for future: add remaining links to the other microsites
	return (
		<Grid container className={classes.toolContainer} direction="column" justifyContent="center" alignItems="center">
			<Grid item container className={classes.toolNavItem} alignItems="center">
				<Grid item>
					<Typography variant="h4">{t('TOOLS')}</Typography>
				</Grid>
				{/* <Grid item>
					<Typography variant="body2">{t('IBAN')}</Typography>
				</Grid> */}
				<Grid item>
					<Link href={PATHS_BY_ENV[env].BEFTNLink} target="_blank" className={classes.link}>
						<Typography variant="body1">{t('BDT_ROUTING_NUM')}</Typography>
					</Link>
				</Grid>
				<Grid item>
					<Link href="/" className={classes.link}>
						<Typography variant="body1" className={classes.boldText}>
							{t('IFSC')}
						</Typography>
					</Link>
				</Grid>
				{/* <Grid item>
					<Typography variant="body2">{t('BIC_SWIFT')}</Typography>
				</Grid> */}
			</Grid>
		</Grid>
	);
};

export default ToolNav;
