import { Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import gradientBackground from '../assets/images/landing/gradientBackground.png';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentContainer: {
			backgroundImage: `url(${gradientBackground})`,
			backgroundRepeat: 'repeat-x repeat-y',
			width: '100%',
			backgroundSize: 'cover',
			[theme.breakpoints.up('md')]: {
				marginTop: 56
			}
		}
	})
);

const GeneralLayout = (props) => {
	const { header, content, footer } = props;
	const classes = useStyles();

	return (
		<Grid container direction="column">
			<Grid item xs={12}>
				{header}
			</Grid>
			<Grid item xs={12} className={classes.contentContainer}>
				{content}
			</Grid>
			<Grid item xs={12}>
				{footer}
			</Grid>
		</Grid>
	);
};

export default GeneralLayout;
