import { Button, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { useStateSafe } from '../effects/useStateSafe';
import DetailsGridColumns from './DetailsGridColumns';
import BasicTextInput from './forms/BasicTextInput';

const useStyles = makeStyles(() => ({
	formCard: {
		padding: 24,
		textAlign: 'left'
	},
	title: {
		fontSize: 24,
		padding: '0px 8px'
	},
	titleContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr auto'
	},
	detailsGrid: {
		padding: 24
	}
}));

const getDisplayDetails = (data, t) => {
	const { address, bank, branch, city, contact, district, ifsc, imps, micr, neft, rtgs, state, upi }: any = data || {};

	return [
		...(ifsc
			? [
					{
						subTitle: t('IFSC_CHECKER_RESULT_CODE'),
						content: ifsc,
						contentVariant: 'h6'
					}
				]
			: []),
		...(bank
			? [
					{
						subTitle: t('IFSC_CHECKER_RESULT_BANK'),
						content: bank,
						contentVariant: 'h6'
					}
				]
			: []),

		...(state
			? [
					{
						subTitle: t('IFSC_CHECKER_RESULT_STATE'),
						content: state,
						contentVariant: 'h6'
					}
				]
			: []),

		...(branch
			? [
					{
						subTitle: t('IFSC_CHECKER_RESULT_BRANCH'),
						content: branch,
						contentVariant: 'h6'
					}
				]
			: []),
		...(address
			? [
					{
						subTitle: t('IFSC_CHECKER_RESULT_BANK_ADD'),
						content: address,
						contentVariant: 'h6'
					}
				]
			: [])
	];
};

const IFSCResultBox = (props: any) => {
	const { result }: any = props || {};
	const classes = useStyles();
	const { t } = useTranslation();
	const [copySuccess, setCopySuccess] = useStateSafe('');
	const textInputRef = useRef(null);

	const copyToClipboard = (text) => {
		textInputRef?.current && textInputRef.current.select();
		setCopySuccess('Copied!');
		return document.execCommand('copy', true, text);
	};

	return (
		<Fragment>
			<Grid item xs={12}>
				<Grid container spacing={2} direction="row" justifyContent="center">
					<Grid item xs={12} md>
						<BasicTextInput type="text" ref={textInputRef} readOnly value={result?.ifsc} showHelperText helperText={copySuccess} />
					</Grid>
					<Grid item xs={12} md={2}>
						<Button variant="contained" color="primary" fullWidth disableElevation onClick={copyToClipboard} style={{ borderRadius: 8 }}>
							{t('IFSC_CHECKER_RESULT_COPY')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid className={classes.detailsGrid} container spacing={2} direction="column" alignItems="flex-start">
					<DetailsGridColumns
						data={getDisplayDetails(result, t)}
						customStyle={{ container: { gridTemplateColumns: '100px auto' }, content: { wordBreak: 'break-word' } }}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

const IFSCCheckResultForm = (props: any) => {
	const { setCurrentStep, result }: any = props || {};
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={2} direction="row" justifyContent="flex-start">
				<Grid item>
					<Button
						color="secondary"
						startIcon={<ArrowBackIosIcon />}
						onClick={() => {
							setCurrentStep(1);
						}}>
						{t('IFSC_CHECKER_RESULT_BACK')}
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="flex-start" justifyContent="center">
						<Grid item>
							<Grid container alignItems="center" className={classes.titleContainer}>
								<FiCheckCircle style={{ width: 36, height: 36, color: '#83DA85' }} />
								<Typography variant="h3" className={classes.title}>
									{t('IFSC_CHECKER_RESULT_TITLE')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('IFSC_CHECKER_RESULT_SUBTITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">{t('IFSC_CHECKER_RESULT_COUNT', { num: result?.length || 0 })}</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						{result && result.length > 0 && result.map((ifscResult, index) => <IFSCResultBox key={index} result={ifscResult} />)}
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

export default IFSCCheckResultForm;
