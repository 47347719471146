import { Button, Grid, Typography, Link } from '@mui/material';
import { memo, useCallback, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import MMLogoSvg from '@assets/svg/mmLogo.svg';
import { LANDING_HEADER_COMPANY, LANDING_HEADER_PRODUCTS, LANDING_HEADER_PROMOTIONS, LANDING_HEADER_SUPPORT } from '@constant/header';
import { PATHS_BY_ENV } from '@constant/paths';
import useScreenViews from '@effects/useScreenViews';
import { useStateSafe } from '@effects/useStateSafe';
import CollapsibleMenu from './CollapsibleMenu';
import MobileHeader from './MobileHeader';
import { env } from '@helpers/EnvHelper';

const useStyles = makeStyles<{
	isFixed: boolean;
}>()((theme, { isFixed }) => ({
	headerContainer: {
		zIndex: 1500,
		position: 'fixed',
		boxShadow: isFixed ? '0 8px 24px rgb(0 0 0 / 13%)' : '',
		backdropFilter: isFixed ? 'blur(60px)' : 'blur(0px)',
		backgroundColor: isFixed ? 'rgba(255, 255, 255, 0.5)' : 'transparent'
	},
	headerSubContainer: {
		width: 1030,
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		height: 56
	},
	mmLogo: {
		cursor: 'pointer'
	},
	loginSignupButton: {
		marginTop: 8,
		height: 41,
		color: theme?.palette?.text?.primary
	},
	tabButton: {
		paddingTop: 17,
		color: theme.palette.accent.main,
		'&:hover': {
			paddingBottom: 4,
			background: 'none',
			borderBottom: `solid 4px ${theme.palette.accent.main}`,
			borderRadius: 0
		}
	},
	darkHeader: {
		backgroundColor: theme?.surfaceVariants?.primary?.onSurface
	},
	darkHeaderBtn: {
		color: theme?.surfaceVariants?.primary?.surface
	}
}));

const Header = (props: any) => {
	const { isBusiness }: any = props || {};
	const [isFixed, setIsFixed] = useStateSafe(false);
	const [lastScrollY, setLastScrollY] = useStateSafe(0);
	const { belowTabletView } = useScreenViews();

	const MMLogo = MMLogoSvg;

	const { classes, cx } = useStyles({ isFixed });

	const controlNavbar = useCallback(() => {
		if (typeof window !== 'undefined') {
			if (window.scrollY > 5) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
			setLastScrollY(window.scrollY);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', controlNavbar);
			return () => {
				window.removeEventListener('scroll', controlNavbar);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastScrollY]);

	if (!belowTabletView) {
		return (
			<Grid
				container
				className={cx(classes.headerContainer, {
					[classes.darkHeader]: isBusiness
				})}
				sx={{ display: { xs: 'none', md: 'flex' } }}
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Grid item container justifyContent="space-between" className={classes.headerSubContainer}>
					<Grid item>
						<Grid container alignItems="center" justifyContent="center" sx={{ height: '100%', margin: 'auto' }}>
							<Link href="/" style={{ textDecoration: 'none' }} color="inherit">
								<img loading="lazy" src={MMLogo} alt="MoneyMatch Logo" width={160} height={40} className={classes.mmLogo} />
							</Link>
						</Grid>
					</Grid>
					<Grid item>
						<Grid item container>
							<Grid item>
								<CollapsibleMenu
									menuButtonStyles={cx({
										[classes.darkHeaderBtn]: isBusiness
									})}
									dataSrc={LANDING_HEADER_PRODUCTS}
									menuText="Product"
								/>
							</Grid>
							<Grid item>
								<Button
									variant="text"
									className={cx(classes.tabButton, {
										[classes.darkHeaderBtn]: isBusiness
									})}
									LinkComponent={Link}
									href={PATHS_BY_ENV[env][LANDING_HEADER_PROMOTIONS.link]}>
									<Typography variant="body1">{LANDING_HEADER_PROMOTIONS.title}</Typography>
								</Button>
							</Grid>
							<Grid item>
								<CollapsibleMenu
									menuButtonStyles={cx({
										[classes.darkHeaderBtn]: isBusiness
									})}
									dataSrc={LANDING_HEADER_COMPANY}
									menuText="Company"
								/>
							</Grid>
							<Grid item>
								<Button
									variant="text"
									className={cx(classes.tabButton, {
										[classes.darkHeaderBtn]: isBusiness
									})}
									LinkComponent={Link}
									href={PATHS_BY_ENV[env][LANDING_HEADER_SUPPORT.link]}>
									<Typography variant="body1">{LANDING_HEADER_SUPPORT?.title}</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container spacing={2} justifyContent="space-between">
							<Grid item>
								<Button
									className={cx(classes.loginSignupButton, {
										[classes.darkHeaderBtn]: isBusiness
									})}
									disableRipple
									color="inherit"
									LinkComponent={Link}
									href={PATHS_BY_ENV[env].loginLink}>
									<Typography variant="body1">Login</Typography>
								</Button>
							</Grid>
							<Grid item>
								<Button
									className={cx(classes.loginSignupButton)}
									disableRipple
									variant="contained"
									color="primary"
									LinkComponent={Link}
									href={PATHS_BY_ENV[env].signupLink}>
									<Typography variant="body1">Sign Up</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			container
			className={classes.headerContainer}
			sx={{ display: { xs: 'flex', md: 'none' } }}
			direction="row"
			justifyContent="center"
			alignItems="center">
			<MobileHeader isBusiness={isBusiness} mMLogo={MMLogo} />
		</Grid>
	);
};

export default memo(Header);
