/* eslint-disable max-len */
const landing = {
	HEADER_TAB_TRANSFER: 'Transfer',
	HEADER_TAB_INSURANCE: 'Insurance',
	HEADER_TAB_SUPPORT: 'Support Center',
	HEADER_TAB_CONTACT: 'Contact Us',

	HEADER_TAB_PRODUCTS: 'Products',
	HEADER_TAB_PROMOTIONS: 'Promotions',
	HEADER_TAB_SUPPORT2: 'Support',
	HEADER_TAB_COMPANY: 'Company',

	LOGIN: 'Login',
	SIGNUP: 'Sign Up',

	NEW: 'NEW',
	BUY_NOW_PAY_LATER: '*Buy Now Pay Later.',
	CONTACT_TO_CHECK: 'Contact us to check eligibility',
	SME_INSURANCE: 'SME Insurance',
	PROTECT_FRM: 'Protect your business from',
	PROTECT_FRM_1: 'workplace hazards.',
	PROTECT_FRM_2: 'unwanted occurrences.',
	PROTECT_FRM_3: 'unforeseen accidents.',
	GET_QUOTE_BTN: 'Get a Quote',
	CONTACT_US_BTN: 'Contact Us',
	ACK_BTN: 'Okay, Got it!',
	SUBMITTING: 'Submitting..',

	TOOLS: 'Tools',
	BDT_ROUTING_NUM: 'BDT Routing Number',
	IBAN: 'IBAN',
	IFSC: 'IFSC',
	BIC_SWIFT: 'BIC/SWIFT',

	CHOOSE_N_CUSTOMIZE_TITLE: 'Choose and Customize',
	CHOOSE_N_CUSTOMIZE_SUBTITLE: 'Conventional or Takaful products from the best insurers for your business.',

	EMPLOYEE_BEN_TITLE: 'Employee Benefits',
	EMPLOYEE_BEN_SUBTITLE: 'Protect one of your company’s most valuable assets — People.',

	GET_FREE_QUOTE_BTN: 'Get a Free Quote',

	EMPLOYEE_BEN_DESC_TITLE: 'Various plans tailored for your needs',
	EMPLOYEE_BEN_DESC_1: 'Personal Accident',
	EMPLOYEE_BEN_DESC_2: 'Accidental Death and Disablement',
	EMPLOYEE_BEN_DESC_3: 'Critical Illness',
	EMPLOYEE_BEN_DESC_4: 'Hopsital and Surgical',
	EMPLOYEE_BEN_DESC_5: 'Foreign Workers Insurance',
	EMPLOYEE_BEN_DESC_6: 'Workemen’s Compensation',
	EMPLOYEE_BEN_DESC_7: 'Medical Insurance',
	EMPLOYEE_BEN_DESC_8: 'Out-Patient Clinical',
	EMPLOYEE_BEN_DESC_9: 'Term Life',

	ASSET_LIABILITY_TITLE: 'Asset & Liability Protection',
	ASSET_LIABILITY_SUBTITLE: 'Securing your business assets from unwanted occurrences.',

	ASSET_LIABILITY_DESC_TITLE: 'Protect your business against unfortunate events',
	ASSET_LIABILITY_DESC_1: 'Fire',
	ASSET_LIABILITY_DESC_2: 'Flood',
	ASSET_LIABILITY_DESC_3: 'Burglary',
	ASSET_LIABILITY_DESC_4: 'Marine',
	ASSET_LIABILITY_DESC_5: 'Machinery Breakdown',
	ASSET_LIABILITY_DESC_6: 'Money',
	ASSET_LIABILITY_DESC_7: 'Consequential Loss',
	ASSET_LIABILITY_DESC_8: 'Motor',
	ASSET_LIABILITY_DESC_9: 'Public Liability',

	WHY_CHOOSE_US: 'Why Choose Us? ',
	BUILT_BUSINESS_IN_MIND: 'Built with <0>businesses</0> in mind.',
	CHOOSE_US_DESC: 'We strive to protect your business and provide solutions to your <0/> insurance needs, so you can focus on growing your business.',

	BUY_NOW_PAY_LATER_TITLE: 'Buy Now, Pay Later ',
	BUY_NOW_PAY_LATER_SUBTITLE: 'Get full protection instantly and split your cost into three equal 0% interest payments.',

	CONVENTIONAL_TITLE: 'Conventional or Takaful',
	CONVENTIONAL_SUBTITLE: `Conventional or Takaful, we help you find the desired insurance for your business' needs.`,

	ENTERPRISE_TITLE: 'Enterprise Focused',
	ENTERPRISE_SUBTITLE: 'Our dedicated relationship managers are committed to provide you with the best service.',

	WIN_COUPON_TITLE: 'Win Coupons and more',
	WIN_COUPON_SUBTITLE: 'Policy holders get rewarded with discounts for remittance transfers.',

	MORE_FEATURE_TITLE: 'More features on the way...',
	MORE_FEATURE_SUBTITLE: 'Get more than just insurance with MoneyMatch.',

	SMART_REMINDER_TITLE: 'Smart Reminders',
	SMART_REMINDER_DESC: `Don't miss out on important payments and renewals anymore`,

	SAVE_POLICY_TITLE: 'Save Policy on Cloud',
	SAVE_POLICY_DESC: 'Easily manage your policies with MoneyMatch Pulse',

	EASY_CLAIM_TITLE: 'Easy Claims',
	EASY_CLAIM_DESC: 'Let our AI-assistant take care of the manual claims entry, while you focus on growing your business.',

	CUSTOMIZED_PERSONAL_PLAN_TITLE: 'Customized Personal Insurance',
	CUSTOMIZED_PERSONAL_PLAN_SUBTITLE: 'Not a Business? Get personal insurances for you and your family!',

	INS_PLAN_CAPTION: '{{i}} of 6',
	INS_PLAN_0_TITLE: 'Motorcycle Plus',
	INS_PLAN_0_DESC: 'Get protection for you & your bike',

	INS_PLAN_1_TITLE: 'Car & Motor Comprehensive',
	INS_PLAN_1_DESC: 'Reliable protection for peace on road',

	INS_PLAN_2_TITLE: 'Travel Care',
	INS_PLAN_2_DESC: 'Enjoy overseas & domestic travels without fear',

	INS_PLAN_3_TITLE: 'Smart Home Cover',
	INS_PLAN_3_DESC: 'Freedom to choose & customise your coverage',

	INS_PLAN_4_TITLE: 'Lifestyle Protect',
	INS_PLAN_4_DESC: `The all-in-one plan for your everyday lifestyle`,

	INS_PLAN_5_TITLE: `Shield Plus`,
	INS_PLAN_5_DESC: `Comprehensive protection for unexpected life events`,

	OUR_PARTNERS: 'Our Partners',

	FAQ: 'Frequently Asked Questions',
	FAQ_TITLE_1: 'What are the insurances offered?',
	FAQ_SUBTITLE_1: 'You can get insured with these insurances:',
	FAQ_DESC_1:
		'Employee Benefits such as Group Hospitalization and Surgical, Fire insurance, Flood insurance, Burglary Insurance, Public Liability Insurance & other customisable insurances.',
	FAQ_TITLE_2: 'Who are the insurance providers?',
	FAQ_SUBTITLE_2: 'Our insurance providers include:',
	FAQ_DESC_2: 'PruBSN,  Allianz Life, Allianz General, and more to come!',
	FAQ_TITLE_3: 'Do we offer Takaful products?',
	FAQ_DESC_3:
		// eslint-disable-next-line
		'Yes, we offer Takaful insurance policies that are Shariah compliant such as Takaful Life or Medical Insurance. Our team consists of MTA registered Takaful agents, authorised to promote and distribute Takaful products.',
	FAQ_TITLE_4: 'What is the premium charged?',
	FAQ_DESC_4:
		// eslint-disable-next-line
		'The premium is calculated based on the insurance policy selected. You can contact our friendly Customer Relationship Manager for more information. Alternatively, you can send us your queries to <0>insurance@moneymatch.co.</0>',

	GOTO_SUPPORT_BTN: 'Go to Support Center',

	CTA_TITLE: 'Have further questions?',
	CTA_SUBTITLE: 'Let’s get in touch!',

	CTA_NAME: 'Your Name',
	CTA_NAME_PH: 'e.g. John Doe',

	CTA_COMPANY_NAME: 'Company Name',
	CTA_COMPANY_NAME_PH: 'e.g. ABC Company Sdn Bhd',

	CTA_CONTACT_NUM: 'Contact Number',
	CTA_CONTACT_NUM_PH: 'e.g. +6012 345 6789',

	CTA_CONTACT_EMAIL: 'Email Address',
	CTA_CONTACT_EMAIL_PH: 'e.g. abc@example.com',

	CTA_COMPANY_SIZE: 'Company Size',
	CTA_COMPANY_SIZE_PH: 'e.g. 50-100 employees',

	CTA_PROTECTION: 'What kind of protection are you looking for?',

	CTA_TELL_US_MORE: 'Tell us what you need',
	CTA_TELL_US_MORE_PH: 'Include more details of your request and any specifics we should take into consideration.',
	CTA_TELL_US_MORE_TXTCOUNTER: '{{len}}/240',
	FORM_SUBMIT: 'Contact Us',

	CTA_SUBMISSION_OK_TITLE: 'Submission Complete!',
	CTA_SUBMISSION_OK_DESC: ' Thank you for your interest. Our team will reach out to you within 3 working days!',

	CONTACT_US_FOOTER: 'Contact Us!',
	CONTACT_US_FOOTER_SUBTITLE: 'We are just one call away.',

	OFFICE_HQ: 'MoneyMatch HQ:',
	OFFICE_HQ_ADDRESS: `Unit 3.03, Menara LGB, 1 Jalan Wan Kadir, <0/> Taman Tun Dr Ismail, Kuala Lumpur, 60000, Malaysia.`,

	OPERATING_INFO: 'Operating Hours',
	OPERATING_HOURS: '9:00 AM - 5:30PM',
	OPERATING_DAYS: '(Weekdays only, except Public Holidays)',

	CALL_OR_TEXT: 'Call or Text Us',
	CALL_CONVENTIONAL_LBL: 'Conventional',
	CALL_CONVENTIONAL_1: '+6019 223 0826 (EN, CN)',
	CALL_CONVENTIONAL_2: '+6019 209 6221 (EN, BM)',
	CALL_CONVENTIONAL_3: '+6019 223 0961 (EN, BM)',

	CALL_TAKAFUL_LBL: 'Takaful',
	CALL_TAKAFUL_1: '+6019 223 0180 (EN, BM)',

	EMAIL_LBL: 'Email Address',
	EMAIL_1: 'insurance@moneymatch.co',
	EMAIL_2: 'customer.support@moneymatch.co',

	COPYRIGHT_FOOTER: 'ALL RIGHTS RESERVED {{year}} MM INSURTECH SDN.BHD. 202201005704 (1451401-K)',

	CHECK_IFSC_TITLE: 'Check or calculate your IFSC Code.',
	CHECK_IFSC_SUBTITLE: `Everything you need to get the right IFSC code for your transfer. Rest assured, we don't keep or view any of the data you enter here.`,

	CHECK_IFSC_FORM_TITLE: 'Check an IFSC',
	CHECK_IFSC_FORM_SUBTITLE: `Type it in here and we'll tell you if it's the right format.`,

	CHECK_IFSC_NAME: 'Enter your IFSC',
	CHECK_IFSC_PH: 'e.g. ICIC0003595',

	CHECK_IFSC_BUTTON: 'Check IFSC',

	FIND_IFSC_FORM_TITLE: 'Find an IFSC',
	FIND_IFSC_FORM_SUBTITLE: `Fill in the bank details, and we'll find an IFSC code for you.`,

	FIND_IFSC_BANK: 'Bank',
	FIND_IFSC_BANK_PH: 'Select a bank',

	FIND_IFSC_STATE: 'State',
	FIND_IFSC_STATE_PH: 'Select a state',

	FIND_IFSC_DISTRICT: 'District',
	FIND_IFSC_DISTRICT_PH: 'Select a district',

	FIND_IFSC_BRANCH: 'Branch',
	FIND_IFSC_BRANCH_PH: 'Select a branch',

	IFSC_CHECKER_RESULT_BACK: 'Back',
	IFSC_CHECKER_RESULT_COPY: 'Copy',
	IFSC_CHECKER_RESULT_TITLE: 'This IFSC code seems right',
	IFSC_CHECKER_RESULT_SUBTITLE: `Remember to check it with your recipient or bank though. We can't tell you if it's real, or if it's the right IFSC code for a particular account.`,
	IFSC_CHECKER_RESULT_COUNT: '{{num}} results found.',

	IFSC_CHECKER_RESULT_CODE: 'IFSC code',
	IFSC_CHECKER_RESULT_BANK: 'Bank',
	IFSC_CHECKER_RESULT_STATE: 'State',
	IFSC_CHECKER_RESULT_BRANCH: 'Branch',
	IFSC_CHECKER_RESULT_BANK_ADD: 'Bank Address',

	IFSC_CTA_GET_FOR_FREE_BTN: 'Get Started for free',
	IFSC_CTA_GET_FOR_FREE_TITLE: 'Got the right information? Now get a better deal for sending money.',
	IFSC_CTA_GET_FOR_FREE_SUBTITLE: 'We offer bank-beating rates and no hidden fees so you can be confident in every transfer you make.',

	IFSC_RETRY_FORM_TITLE: `These details don't look right`,
	IFSC_RETRY_FORM_SUBTITLE: `Type it in here and we'll tell you if it's the right format.`,

	WHAT_IS_IFSC: 'What is an IFSC Code?',
	WHAT_IS_IFSC_DESC:
		'IFSC is short for Indian Financial System Code and represents the 11 digit character that you can usually see on your bank’s cheque leaves, or other bank sponsored material. This 11 character code helps identify the individual bank branches in India. Every bank branch will have a unique code and no two branches (even of the same bank) will ever be the same.',
	IFSC_EXAMPLE_HEADER: 'An example of an IFSC Code ',
	BANK_CODE: 'Bank code',
	BANK_CODE_EXAMPLE: 'ABCD',
	CHECK_DIGITS: 'Check digits',
	CHECK_DIGITS_EXAMPLE: '0',
	BRANCH_CODE: 'Branch code',
	BRANCH_CODE_EXAMPLE: '123***',

	WHEN_NEED_IFSC: 'When will you need an IFSC code?',
	WHEN_NEED_IFSC_DESC:
		'An IFSC Code is required when you are making an INR transfer to India, particularly via transfer options like NEFT an RTGS. This code is mandatory for fund transfers from one bank account to another. ',
	ALWAYS_CONFIRM_DETAILS: 'Always confirm the details with your recipient',
	ALWAYS_CONFIRM_DETAILS_DESC:
		'We can tell whether your IFSC is in the valid format, but we can’t guarantee that it exists, or that it represents the intended account. This tool should help you to confirm some of the details you already know, such as the country and bank. <0/><0/>When sending or receiving money, always check the IFSC code with your recipient or bank.',
	WRONG_IFSC: 'What will happen if I have used the wrong IFSC?',
	WRONG_IFSC_DESC:
		'If you think you’ve used the wrong IFSC to send money, contact us immediately. Depending on what extent has the order been processed, we will notify you accordingly on the available solutions <0/> <0/> MoneyMatch cannot reverse or recall successful payments. Once the funds have been credited to your recipient account, the order cannot be recalled or refunded.',
	JOIN_COMMUNITY:
		'Join our community of more than 100,000 users who rely on MoneyMatch every day to send money home to their families, pay for goods and services abroad or expand their payment network around the world.',
	TRANSFER_SOLUTION: 'The transfer solution for<0/>everyone',
	WE_GOT_YOU_COVERED: 'We’ve got you covered!',

	MM_ALL_RIGHTS_RESERVED: 'MoneyMatch Sdn.Bhd. {{year}}. All rights reserved.',
	MM_ALL_RIGHTS_RESERVED2: 'Sitemap • Legal • Privacy Policy',
	MM_IS_REGULATED:
		'MoneyMatch Sdn. Bhd. is regulated by Central Bank (licensee providing digital services) under MY Licence Serial Number 00249 in Malaysia.<0/>Perniagaan Perkhidmatan Wang Berlesen | Pengirim Wang',

	TRANSFER: 'Transfer',
	TRANSFER_BUSINESS: 'Transfer for Business',
	TRANSFER_DESC: 'Say farewell to expensive fees when you send money abroad to friends and family',
	TRANSFER_BUSINESS_DESC: 'Outsmart your compentitors. Best exchange rates and flat fees when making overseas business payments',
	SME_INSURANCE_DESC: 'Get Conventional or Takaful products from best insurers for your business',
	ABOUT: 'About MoneyMatch',
	ABOUT_DESC: 'A snapshot of who we are',
	BLOG: 'Blog',
	BLOG_DESC: 'Our insights and more...',
	CAREERS: 'Careers',
	CAREERS_DESC: 'Join us on our mission to redefine finance!'
};

export default landing;
