import { FOOTER_BUSINESS, FOOTER_COMPANY, FOOTER_ENTERPRISE, FOOTER_PERSONAL, FOOTER_RESOURCES } from '@constant/footer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Link } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PATHS_BY_ENV } from '@constant/paths';
import { env } from '@helpers/EnvHelper';

const useStyles = makeStyles()((theme) => ({
	mobileLayout: {
		width: '100%',
		fontSize: '14px',
		lineHeight: '21px'
	},
	text: {
		color: theme.palette.grey[900],
		lineHeight: '21px'
	},
	wrapMobileLayout: {
		background: theme.palette.background.paper,
		border: 'none'
	}
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const FooterLinks = (props) => {
	const { data } = props || {};
	const { classes } = useStyles();

	if (!data) return <></>;

	return (
		<>
			{data?.map((item, index) => {
				if (item?.link && item?.title)
					return (
						<React.Fragment key={index}>
							<Link className={classes.text} style={{ textDecoration: 'none' }} href={`${PATHS_BY_ENV[env][item?.link]}${item?.propsLink ?? ''}`}>
								<Typography variant="body1" mb={1.5}>
									{item?.title}
								</Typography>
							</Link>
						</React.Fragment>
					);

				return null;
			})}
		</>
	);
};

export default function MobileFooter() {
	const { classes } = useStyles();

	return (
		<div className={classes.mobileLayout}>
			<Accordion className={classes.wrapMobileLayout}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
					<Typography variant="h6">Personal</Typography>
				</AccordionSummary>

				<AccordionDetails sx={{ padding: '0px 48px 0px' }}>
					<FooterLinks data={FOOTER_PERSONAL} />
				</AccordionDetails>
			</Accordion>

			<Accordion className={classes.wrapMobileLayout}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
					<Typography variant="h6">Business</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px 48px 0px' }}>
					<FooterLinks data={FOOTER_BUSINESS} />
				</AccordionDetails>
			</Accordion>

			<Accordion className={classes.wrapMobileLayout}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
					<Typography variant="h6">Enterprise</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px 48px 0px' }}>
					<FooterLinks data={FOOTER_ENTERPRISE} />
				</AccordionDetails>
			</Accordion>

			<Accordion className={classes.wrapMobileLayout}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
					<Typography variant="h6">Resources</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px 48px 0px' }}>
					<FooterLinks data={FOOTER_RESOURCES} />
				</AccordionDetails>
			</Accordion>

			<Accordion className={classes.wrapMobileLayout}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
					<Typography variant="h6">Company</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px 48px 0px' }}>
					<FooterLinks data={FOOTER_COMPANY} />
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
