import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const JoinOurCommunity = () => {
	const { t } = useTranslation();

	return (
		<Grid container justifyContent="center">
			<Typography variant="h3">{t('JOIN_COMMUNITY')}</Typography>
		</Grid>
	);
};

export default JoinOurCommunity;
