import { Button, Grid, Link, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import transferSolution from '../../assets/images/landing/transferSolution.png';
import { PATHS_BY_ENV } from '../../constant/paths';
import useScreenViews from '../../effects/useScreenViews';
import { env } from '../../helpers/EnvHelper';
import { useCommonStyles } from '../../styles/common';
import SplitScreen from '../SplitScreen';

const useStyles = makeStyles<Theme, any>((theme) => ({
	transferSolution: ({ belowTabletView }) => ({
		textAlign: belowTabletView ? 'center' : 'right'
	}),
	ctaButtonContainer: {
		marginTop: '16px !important'
	},
	ctaButton: {
		padding: '10px 24px'
	},
	splitContainer: ({ belowMobileView }) => ({
		backgroundColor: '#FAFAFA',
		paddingBottom: belowMobileView ? '1.5rem' : '0'
	})
}));

const Desc = () => {
	const { t } = useTranslation();
	const { belowMobileView, belowTabletView } = useScreenViews();
	const classes = useStyles({ belowMobileView, belowTabletView });
	const clsx = useCommonStyles({});

	return (
		<Grid container direction="column" justifyContent="center" alignItems="flex-end">
			<Grid container style={{ maxWidth: belowTabletView ? '100%' : '70%' }} direction="row" justifyContent="flex-end" alignItems="flex-end">
				<Grid item xs={12}>
					<Typography variant="h2" className={classes.transferSolution}>
						<Trans i18nKey="TRANSFER_SOLUTION" components={[<br />]} />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.transferSolution} variant="body1">
						{t('WE_GOT_YOU_COVERED')}
					</Typography>
				</Grid>
				<Grid item container justifyContent={belowTabletView ? 'center' : 'flex-end'} className={classes.ctaButtonContainer}>
					<Button className={`${classes.ctaButton} ${clsx.mmtButtonPurple}`}>
						<Link href={PATHS_BY_ENV[env].loginLink} target="_blank" rel="noopener noreferrer" underline="none" color="inherit">
							{t('IFSC_CTA_GET_FOR_FREE_BTN')}
						</Link>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const TransferSolution = () => {
	const { belowMobileView, belowTabletView } = useScreenViews();
	const classes = useStyles({ belowMobileView });
	const ContentArr = [
		<Desc key="transferSolutionDesc" />,
		<img style={{ paddingLeft: 100 }} alt="transferSolution" key="transferSolutionimg" src={transferSolution} />
	];
	const orderedChildren = belowTabletView ? ContentArr.reverse() : ContentArr;

	return (
		<SplitScreen
			className={classes.splitContainer}
			leftJustify={belowTabletView ? 'center' : 'flex-end'}
			rightJustify={belowTabletView ? 'center' : 'flex-start'}>
			{orderedChildren}
		</SplitScreen>
	);
};

export default TransferSolution;
