import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './App.css';
import ZendeskChatbot from './components/ZendeskChatbot';
import AppRoutes from './routes/AppRoutes';
import theme from './theme/globalTheme';
import { clarity } from 'clarity-js';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const App = () => {
	useEffect(() => {
		window.process = {
			...window?.process
		};

		initClarityTracker();

		return () => {
			clarity.stop();
		};
	}, []);

	const initClarityTracker = () => {
		clarity.consent();
		clarity.start({
			projectId: process.env.REACT_APP_CLARITY_KEY || '',
			upload: 'https://m.clarity.ms/collect',
			track: true,
			content: true
		});
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHAKEY} scriptProps={{ async: true }}>
					<AppRoutes />
					{/* <ZendeskChatbot /> */}
				</GoogleReCaptchaProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
