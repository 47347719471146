/* eslint-disable max-len */
const common = {
	SELECT_LANGUAGE: 'Please select language',
	ENGLISH: 'English',
	CHINESE: '中文',
	MALAY: 'Bahasa Malaysia',
	WELCOME_MSG: 'Welcome, {{userName}}!',
	WELCOME_BACK_MSG: 'Welcome back, {{userName}}!',
	FEATURE_DASHBOARD: 'Dashboard',
	FEATURE_BULK_UPLOAD: 'Bulk Upload',
	FEATURE_TRANS: 'Transfer',
	FEATURE_DIRECT_TRANS: 'Direct Transfer',
	FEATURE_ACCOUNT: 'Accounts',
	FEATURE_ACTIVITIES: 'Activities',
	FEATURE_INSURANCE: 'Insurance',
	FEATURE_FINANCING: 'Financing',
	FEATURE_REQ_FOR_PRICE: 'Request For Price',
	RECORDS_PER_PAGE_LBL: 'records per page',
	PREVIOUS: 'Previous',
	NEXT: 'Next',
	SEARCH_PLACEHOLDER: 'Search',
	SEARCH_PLACEHOLDER_ACTIVITIES: 'Search Order Ref, beneficiary name, currency...',
	APPLY_LBL: 'Apply',
	REMOVE_LBL: 'Remove',
	SAVE_LBL: 'Save',
	PROCEED_LBL: 'Proceed',
	CANCEL_LBL: 'Cancel',
	SUBMIT_LBL: 'Submit',
	LOADING_LBL: 'Loading',
	VALIDATE_LBL: 'Validate',
	SUBMIT_ORDER_LBL: 'Submit Order',
	CONTINUE_LBL: 'Continue',
	CLOSE_LBL: 'Close',
	REQUIRED: 'required*',
	PHONE_NUMBER_ERR: 'please enter a valid mobile number format',
	EMAIL_ERR: 'please enter a valid email',
	MAX_CHARS: 'Current field allows maximum 240 characters',
	INVALID_DATE_ERR: 'invalid date selected*',
	PRICE_MUST_POSITIVE_ERR: 'must be positive number*',
	BACK_LBL: 'Back',
	LOG_OUT_LBL: 'Log Out',
	STAY_ACTIVE_LBL: 'Stay Active',
	DROP_DOWN_PLACEHOLDER: '- Please Select One -',
	BACK_TO_DASH_LBL: 'Back to Dashboard',
	YES: 'Yes',
	NO: 'No',
	'Manual Deposit': 'Upload Payment Receipt',
	FOOTER_CONTACT_ACC_MANAGER: `<0/><0/>Interested to know more? Get in touch with your Corporate Relationship Managers or call our hotlines at <1>+603-30993889 to speak with our Communication Personnel</1>`,
	PLEASE_SPECIFY: 'Please specify',
	HAS_EXISTING_YES: 'Yes',
	HAS_EXISTING_NO: 'No, or prefer not to say',
	OKAY_LBL: 'Okay!',
	LOGIN_SESSION_EXPIRING_TITLE: `Login Session Is Expiring!`,
	LOGIN_SESSION_EXPIRING_CONTENT: `We are improving to keep you safe and connected.\nYou will be log out in 10 seconds.\n Please re-login to stay active.`,
	PAYMENT_DROP_DOWN_LBL: 'Select bank for FPX',
	NO_RESULT_EMPTY_FILE_TITLE: 'No files attached yet',
	NO_RESULT_EMPTY_FILE_SUBTITLE: 'Try uploading new files to the upload box.',
	IFSC_INPUT_LENGTH: 'The length of this IFSC is incorrect ({{len}} characters). The correct length should be 11 characters.'
};

export default common;
