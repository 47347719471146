import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import customTypography from './typography';

declare module '@mui/material/styles' {
	interface CustomTheme {
		surfaces?: {
			level1?: string;
			level2?: string;
			level3?: string;
			level4?: string;
			level5?: string;
			contrastText: string;
		};
		surfaceVariants?: {
			primary?: {
				surface?: string;
				onSurface?: string;
			};
			secondary?: {
				surface?: string;
				onSurface?: string;
			};
		};
		outline?: {
			main?: string;
		};
	}

	interface Palette {
		accent: Palette['primary'];
		mmtBackground: Palette['primary'];
	}
	interface PaletteOptions {
		accent: PaletteOptions['primary'];
		mmtBackground: PaletteOptions['primary'];
	}

	interface Theme extends CustomTheme {}
	interface ThemeOptions extends CustomTheme {}
}

let theme = createTheme({
	palette: {
		background: {
			default: '#F7F7F7',
			paper: '#FFFFFF'
		},
		primary: {
			main: '#F7E21E'
		},
		secondary: {
			main: '#5D65F8'
		},
		error: {
			dark: '#680003',
			main: '#BA1B1B',
			light: '#FFDAD4'
		},
		accent: {
			main: '#333333',
			light: '#CCCCCC',
			dark: '#9B9A97'
		},
		mmtBackground: {
			light: '#FAFAFA',
			main: '#F2F2F2',
			dark: '#E5E5E5'
		}
	},
	surfaces: {
		level1: '#FCFCFC',
		level2: '#F5F5F5',
		level3: '#EEEEEE',
		level4: '#E0E0E0',
		level5: '#BDBDBD',
		contrastText: '#757575'
	},
	surfaceVariants: {
		primary: {
			surface: '#FFFFFF',
			onSurface: '#212121'
		},
		secondary: {
			surface: '#EEEEEE',
			onSurface: '#757575'
		}
	},
	// @ts-ignore
	typography: customTypography,
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					minHeight: 41,
					borderRadius: '360px'
				},
				contained: {
					padding: '12px 16px'
				},
				containedPrimary: {
					'&:hover': {
						background: '#FFF38B',
						color: '#212121'
					}
				},
				text: {
					'&:hover': {
						background: 'none'
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF'
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					zIndex: 3000
				}
			}
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920
		}
	}
});

theme = responsiveFontSizes(theme);

export default theme;
