import { Button, Card, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useScreenViews from '../effects/useScreenViews';
import { useStateSafe } from '../effects/useStateSafe';
import { isEmpty } from '../helpers/String';
import IFSCService from '../network/api/ifsc';
import BasicTextInput from './forms/BasicTextInput';

const useStyles = makeStyles<Theme, any>((theme) => ({
	formCard: ({ belowMobileView }) => ({
		padding: 24,
		width: belowMobileView ? '90%' : '45%'
	}),
	formGridContainer: {
		textAlign: 'left'
	}
}));

const IFSCRetryForm = (props: any) => {
	const { belowMobileView } = useScreenViews();
	const { setCurrentStep, ifscNumber, errorMsg, setResult, setIfscNumber }: any = props || {};
	const classes = useStyles({ belowMobileView });
	const { t } = useTranslation();
	const [newErrorMsg, setNewErrorMsg] = useStateSafe(errorMsg || '');
	const ifscService = new IFSCService();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const validationSchema = yup.object({
		ifscNumber: yup
			.string()
			.min(11, (obj) => t('IFSC_INPUT_LENGTH', { len: obj.value.length }))
			.max(11, (obj) => t('IFSC_INPUT_LENGTH', { len: obj.value.length }))
			.required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			ifscNumber
		},
		enableReinitialize: true,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { ifscNumber: retryIfscNumber }: any = values || {};
				const newToken = executeRecaptcha && (await executeRecaptcha('IFSCRetryForm'));
				const result = await ifscService.getIFSCByCode(retryIfscNumber, newToken);
				const { data: resultData } = result;

				if (!isEmpty(resultData)) {
					setIfscNumber(retryIfscNumber);
					setResult && setResult([resultData]);
					setCurrentStep && setCurrentStep(2);
				}
			} catch (err: any) {
				const { message, statusCode, status }: any = err?.response?.data || {};

				setNewErrorMsg && setNewErrorMsg(message);
				setCurrentStep && setCurrentStep(3);
				// eslint-disable-next-line no-console
				console.error('Error Retry IFSC Checking', err);
			}
		}
	});

	return (
		<Grid container justifyContent="center">
			<Card elevation={3} className={classes.formCard}>
				<Grid container spacing={2} className={classes.formGridContainer} direction="column" justifyContent="center">
					<Grid item xs={12}>
						<Button
							color="secondary"
							startIcon={<ArrowBackIosIcon />}
							onClick={() => {
								setCurrentStep(1);
							}}>
							{t('IFSC_CHECKER_RESULT_BACK')}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} direction="column" alignItems="flex-start" justifyContent="center">
							<Grid item>
								<Typography variant="h3">{t('IFSC_RETRY_FORM_TITLE')}</Typography>
							</Grid>
							<Grid item>
								<Typography variant="subtitle1">{t('IFSC_RETRY_FORM_SUBTITLE')}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<BasicTextInput
							label="CHECK_IFSC_NAME"
							type="text"
							name="ifscname"
							value={formik.values.ifscNumber}
							placeholder={t('CHECK_IFSC_PH')}
							onChange={(e) => {
								setNewErrorMsg('');
								formik.setFieldValue('ifscNumber', e.target.value);
							}}
							onBlur={formik.handleBlur}
							error={Boolean(newErrorMsg) || (formik.touched.ifscNumber && Boolean(formik.errors.ifscNumber))}
							helperText={newErrorMsg || formik.errors.ifscNumber}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" fullWidth disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
							{formik.isSubmitting ? t('SUBMITTING') : t('CHECK_IFSC_BUTTON')}
						</Button>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
};

export default IFSCRetryForm;
