export const isArray = (array) => Array.isArray(array);
export const isEmptyArray = (array) => isArray(array) && array.length === 0;
export const isArraysEqual = (a, b) => {
	a = isArray(a) ? a : [];
	b = isArray(b) ? b : [];
	return a.length === b.length && a.every((el, ix) => el === b[ix]);
};
export const sortCompareBigger = (a, b) => a - b;
export const sortCompareSmaller = (a, b) => b - a;

export const sortCompareStrBigger = (a, b) => {
	try {
		return a.localeCompare(b);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Error sortCompareStrBigger:   ', err);
	}
};

export const sortCompareStrSmaller = (a, b) => {
	try {
		return b.localeCompare(a);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Error sortCompareStrSmaller:   ', err);
	}
};
