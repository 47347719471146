import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme, any>((theme) => ({
	title: {
		fontSize: 40
	},
	subtitle: {
		fontSize: 16,
		fontWeight: 400
	},
	titleContainer: {
		padding: '0px 16px'
	},
	subtitleContainer: {
		width: '70%',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		}
	},
	childContainer: {
		marginTop: '2rem',
		width: '90%',
		[theme.breakpoints.up('lg')]: {
			width: '70%'
		},
		'& > .MuiGrid-container': {
			marginBottom: 64
		}
	},
	ifscContainer: {
		textAlign: 'center',
		marginTop: '3rem'
	}
}));

const IfscContainer = (props) => {
	const { children }: any = props || {};
	const { t } = useTranslation();
	const classes = useStyles({});

	return (
		<Grid container className={classes.ifscContainer} direction="column" justifyContent="center" alignItems="center">
			<Grid item>
				<Grid container className={classes.titleContainer} direction="column" justifyContent="center" alignItems="center">
					<Grid item>
						<Typography className={classes.title} variant="h2">
							{t('CHECK_IFSC_TITLE')}
						</Typography>
					</Grid>
					<Grid item className={classes.subtitleContainer}>
						<Typography className={classes.subtitle} variant="h5">
							{t('CHECK_IFSC_SUBTITLE')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className={classes.childContainer}>
				{children}
			</Grid>
		</Grid>
	);
};

export default IfscContainer;
