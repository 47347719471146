import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WhenNeededSVG } from '../../assets/svg/infos/whenNeeded.svg';
import SplitScreen from '../SplitScreen';

const Desc = () => {
	const { t } = useTranslation();
	return (
		<Grid container direction="column" justifyContent="center">
			<Grid item>
				<Typography variant="h3">{t('WHEN_NEED_IFSC')}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">{t('WHEN_NEED_IFSC_DESC')}</Typography>
			</Grid>
		</Grid>
	);
};

const WhenIFSCNeeded = () => {
	return (
		<SplitScreen>
			<WhenNeededSVG />
			<Desc />
		</SplitScreen>
	);
};

export default WhenIFSCNeeded;
