import { Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ctaGetNewFree from '../assets/images/landing/ctaGetNewFree.png';
import { PATHS_BY_ENV } from '../constant/paths';
import { env } from '../helpers/EnvHelper';
import MmtButton from './MmtButton';

const useStyles = makeStyles(() => ({
	formCard: {
		padding: '24px',
		maxHeight: '480px'
	},
	ctaContainer: {
		textAlign: 'center',
		height: '100%',
		width: '100%'
	},
	img: {
		minHeight: 214,
		width: '80%'
	}
}));

const GetNewCTA = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const onCTAClick = () => {
		window.location.assign(PATHS_BY_ENV[env]?.loginLink);
	};

	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={4} direction="column" justifyContent="center">
				<Grid item xs={12} className={classes.ctaContainer}>
					<img alt="ctaGetNewFree" src={ctaGetNewFree} className={classes.img} />
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Typography variant="h4">{t('IFSC_CTA_GET_FOR_FREE_TITLE')}</Typography>
						<Typography variant="subtitle1">{t('IFSC_CTA_GET_FOR_FREE_SUBTITLE')}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<MmtButton fullWidth onClick={onCTAClick}>
						{t('IFSC_CTA_GET_FOR_FREE_BTN')}
					</MmtButton>
				</Grid>
			</Grid>
		</Card>
	);
};

export default GetNewCTA;
