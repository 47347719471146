import { Button } from '@mui/material';
import { useCommonStyles } from '../styles/common';

const MmtButton = (props) => {
	const { className, children, onClick, ...remainProps } = props;
	const commonClasses = useCommonStyles({});

	return (
		<Button {...remainProps} className={className || commonClasses.mmtButtonPurple} onClick={onClick}>
			{children}
		</Button>
	);
};

export default MmtButton;
