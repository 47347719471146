import AUFlag from '@assets/images/regions/AUFlag.png';
import BNFlag from '@assets/images/regions/BNFlag.png';
import MYFlag from '@assets/images/regions/MYFlag.png';
import SGFlag from '@assets/images/regions/SGFlag.png';
import HKFlag from '@assets/images/regions/HKFlag.png';
import NZFlag from '@assets/images/regions/NZFlag.png';
import { PATHS_BY_ENV } from '@constant/paths';
import { env } from '@helpers/EnvHelper';

export const DEFAULT_REGION = 'MY';
export const LAST_VISITED_REGION = 'lastVisitedRegion';
export const PATHNAME_REGION = 'REGION';
export const USER_STOP_REGION_TOPBAR = 'isUserStopRegionTopBar';

export enum MMT_REGION_NAME {
	MY = 'Malaysia',
	BN = 'Brunei',
	AU = 'Australia',
	SG = 'Singapore',
	HK = 'Hong Kong',
	NZ = 'New Zealand'
}

const TRANSFER_LINK_BY_REGION = {
	[MMT_REGION_NAME.MY]: PATHS_BY_ENV[env].transferLink,
	[MMT_REGION_NAME.AU]: PATHS_BY_ENV[env].transferLinkAU,
	[MMT_REGION_NAME.BN]: PATHS_BY_ENV[env].transferLinkBN,
	[MMT_REGION_NAME.NZ]: PATHS_BY_ENV[env].transferLinkNZ,
	[MMT_REGION_NAME.SG]: PATHS_BY_ENV[env].transferLinkSG,
	[MMT_REGION_NAME.HK]: PATHS_BY_ENV[env].transferLinkHK
};

export const SUPPORTED_REGION = [
	{
		imgUrl: MYFlag,
		countryCode: 'MY',
		name: MMT_REGION_NAME.MY,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.MY],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.MY]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.MY]}/login`,
		isActive: true
	},
	{
		imgUrl: AUFlag,
		countryCode: 'AU',
		name: MMT_REGION_NAME.AU,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.AU],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.AU]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.AU]}/login`,
		isActive: true
	},
	{
		imgUrl: BNFlag,
		countryCode: 'BN',
		name: MMT_REGION_NAME.BN,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.BN],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.BN]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.BN]}/login`,
		isActive: true
	},
	{
		imgUrl: SGFlag,
		countryCode: 'SG',
		name: MMT_REGION_NAME.SG,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.SG],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.SG]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.SG]}/login`,
		isActive: false
	},
	{
		imgUrl: HKFlag,
		countryCode: 'HK',
		name: MMT_REGION_NAME.HK,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.HK],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.HK]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.HK]}/login`,
		isActive: false
	},
	{
		imgUrl: NZFlag,
		countryCode: 'NZ',
		name: MMT_REGION_NAME.NZ,
		transferLink: TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.NZ],
		registerLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.NZ]}/register`,
		loginLink: `${TRANSFER_LINK_BY_REGION[MMT_REGION_NAME.NZ]}/login`,
		isActive: true
	}
];
