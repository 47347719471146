import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
	wrapGridItem: {
		width: '1030px',
		[theme.breakpoints.down('lg')]: {
			width: '90%'
		},
		[theme.breakpoints.up('lg')]: {
			width: '70%'
		}
	}
}));

const CustomGrid = ({ children }) => {
	const { classes } = useStyles();

	return (
		<Grid container justifyContent={'center'}>
			<Grid item className={classes.wrapGridItem}>
				{children}
			</Grid>
		</Grid>
	);
};

export default CustomGrid;
