import { TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrorHelperText from './FormErrorHelperText';

const useStyles = makeStyles<Theme, any>((theme) => ({
	label: {
		width: '100%'
	},
	container: {
		minHeight: 56
	},
	autoComplete: {
		'& .MuiAutocomplete-tag': {
			background: 'rgba(247, 226, 30, 0.2)'
		},
		'&  .MuiSvgIcon-root': {
			color: '#333333'
		}
	},
	disabledBackgroundColor: {
		backgroundColor: '#D3D3D3'
	}
}));

const MultiSelectDropdown = (props) => {
	const classes = useStyles({});
	const { t } = useTranslation();

	const { label, options, error, onChange, value: inputValue, placeholder, helperText, style, ...rest } = props || {};

	return (
		<>
			<Typography variant="h6" className={classes.label}>
				{t(label)}
			</Typography>
			<Autocomplete
				className={rest?.disabled ? classes.disabledBackgroundColor : classes.autoComplete}
				style={{ root: classes.container, ...style }}
				{...rest}
				options={options}
				getOptionLabel={(option) => option}
				value={inputValue}
				onChange={onChange}
				renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t(placeholder)} color="primary" />}
			/>
			{error && <FormErrorHelperText helperText={helperText} />}
		</>
	);
};
export default memo(MultiSelectDropdown);
