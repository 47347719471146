// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fontFamily: ['Poppins'],
	h1: {
		fontSize: '3rem',
		fontWeight: 400,
		lineHeight: 1.25,
		letterSpacing: 0
	},
	h2: {
		fontSize: '2.5rem',
		fontWeight: 700,
		lineHeight: 1.25,
		letterSpacing: 0
	},
	h3: {
		fontSize: '1.5rem',
		fontWeight: 700,
		lineHeight: 1.5,
		letterSpacing: 0
	},
	h4: {
		fontSize: '1rem',
		fontWeight: 700,
		lineHeight: 1.5,
		letterSpacing: 0
	},
	h5: {
		fontSize: '1rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: 0
	},
	h6: {
		fontSize: '0.875rem',
		fontWeight: 600,
		lineHeight: 1.5,
		letterSpacing: '-2%'
	},
	subtitle1: {
		fontSize: '0.875rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: '1.5%'
	},
	subtitle2: {
		fontSize: '0.625rem',
		fontWeight: 600,
		lineHeight: 1.5,
		letterSpacing: 0
	},
	body1: {
		fontSize: '0.875rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: 0
	},
	body2: {
		fontSize: '0.75rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: '-2%'
	},
	button: {
		fontSize: '1rem',
		fontWeight: 600,
		lineHeight: 1.5,
		letterSpacing: 0,
		textTransform: 'none'
	},
	caption: {
		fontSize: '0.625rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: '0.4%'
	},
	overline: {
		fontSize: '0.625rem',
		fontWeight: 400,
		lineHeight: 1.5,
		letterSpacing: '1.5%'
	}
};
