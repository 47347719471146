import Footer from '@components/shared/footer/Footer';
import Header from '@components/shared/header/Header';
import IFSCFinder from '../components/IFSCFinder';
import IFSCResult from '../components/IFSCResult';
import IFSCRetryForm from '../components/IFSCRetryForm';
import JoinOurCommunity from '../components/JoinOurCommunity';
import IfscContainer from '../components/section/IfscContainer';
import AlwaysConfirm from '../components/splitScreenComponents/AlwaysConfirm';
import TransferSolution from '../components/splitScreenComponents/TransferSolution';
import WhenIFSCNeeded from '../components/splitScreenComponents/WhenIFSCNeeded';
import WrongIFSCConsequences from '../components/splitScreenComponents/WrongIFSCConsequences';
import ToolNav from '../components/ToolNav';
import WhatIsIFSC from '../components/WhatIsIFSC';
import { useStateSafe } from '../effects/useStateSafe';
import GeneralLayout from '../layouts/GeneralLayout';
import { useVisitedRegion } from '@effects/useVisitedRegion';

const IFSCSteps = (props) => {
	const { currentStep, setCurrentStep } = props;
	const [ifscNumber, setIfscNumber] = useStateSafe<string>('');
	const [result, setResult] = useStateSafe({});
	const [errorMsg, setErrorMsg] = useStateSafe<string>('');

	switch (currentStep) {
		case 1:
			return (
				<IFSCFinder
					setCurrentStep={setCurrentStep}
					ifscNumber={ifscNumber}
					setIfscNumber={setIfscNumber}
					setErrorMsg={setErrorMsg}
					setResult={setResult}
				/>
			);
		case 2:
			return <IFSCResult setCurrentStep={setCurrentStep} ifscNumber={ifscNumber} result={result} />;
		case 3:
			return (
				<IFSCRetryForm
					setCurrentStep={setCurrentStep}
					setIfscNumber={setIfscNumber}
					ifscNumber={ifscNumber}
					errorMsg={errorMsg}
					setResult={setResult}
				/>
			);
	}
};

const Content = (props) => {
	const { currentStep, setCurrentStep } = props;

	return (
		<>
			<ToolNav />
			<IfscContainer>
				<IFSCSteps currentStep={currentStep} setCurrentStep={setCurrentStep} />
				<WhatIsIFSC />
				<WhenIFSCNeeded />
				<AlwaysConfirm />
				<WrongIFSCConsequences />
				<JoinOurCommunity />
			</IfscContainer>
			<TransferSolution />
		</>
	);
};

const LandingPage = () => {
	const [currentStep, setCurrentStep] = useStateSafe<number>(1);
	const { countryNameToDisplay } = useVisitedRegion();

	return (
		<GeneralLayout
			header={<Header setCurrentStep={setCurrentStep} />}
			content={<Content currentStep={currentStep} setCurrentStep={setCurrentStep} />}
			footer={<Footer countryNameToDisplay={countryNameToDisplay} />}
		/>
	);
};

export default LandingPage;
