import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as WarningSVG } from '../../assets/svg/infos/warning.svg';
import SplitScreen from '../SplitScreen';

const Desc = () => {
	const { t } = useTranslation();
	return (
		<Grid container direction="column" justifyContent="center">
			<Grid item>
				<Typography variant="h3">{t('WRONG_IFSC')}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">
					<Trans i18nKey="WRONG_IFSC_DESC" components={[<br />]} />
				</Typography>
			</Grid>
		</Grid>
	);
};

const WrongIFSCConsequences = () => {
	return (
		<SplitScreen>
			<WarningSVG />
			<Desc />
		</SplitScreen>
	);
};

export default WrongIFSCConsequences;
