// ---------------------------------- HEADER NAVBAR STARTS HERE-------------------------------------------------------
export const LANDING_HEADER_SUPPORT = {
	title: 'Support',
	link: 'supportCenterLink'
};

export const LANDING_HEADER_PROMOTIONS = {
	title: 'Promotions',
	link: 'promotionsLink'
};

export const LANDING_HEADER_PRODUCTS = [
	{
		title: 'Personal',
		components: [
			{ title: 'Bank Transfers', link: 'personalTransfer' },
			{ title: 'Mobile Wallet Transfers', link: 'personalTransfer', propsLink: '#platform' },
			{ title: 'Cash Pickup', link: 'personalTransfer' },
			{ title: 'Insurances', link: 'insuranceLink' },
			{ title: 'Referral Program', link: 'referralProgramLink' }
		]
	},
	{
		title: 'Business',
		components: [
			{ title: 'Transfer', link: 'businessTransfer' },
			{ title: 'Insurance', link: 'insuranceLink' }
		]
	},
	{
		title: 'Enterprise',
		components: [
			{ title: 'Payment Network', link: 'enterpriseLink' },
			{ title: 'Become an Agent', link: 'agentsLink' }
		]
	}
];

export const LANDING_HEADER_COMPANY = [
	{
		title: 'Company',
		components: [
			{ title: 'About Us', link: 'aboutLink' },
			{ title: 'Careers', link: 'careersLink' },
			{ title: 'News and Media', link: 'newsLink' },
			{ title: 'Blog', link: 'blogLink' },
			{ title: 'Contact Us', link: 'contactUsLink' }
		]
	},
	{
		title: 'Resources',
		components: [
			{ title: 'API Reference', link: 'apiRefLink' },
			{ title: 'BEFTN Routing Number', link: 'BEFTNLink' },
			{ title: 'IFSC Codes', link: 'IFSCLink' }
			// { title: 'Coverage', link: '' } TO D O : ADD LINK ONCE PAGE IS THERE
		]
	}
];
// ---------------------------------- HEADER NAVBAR ENDS HERE-------------------------------------------------------
