import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from '../src/locale/en/index';
import msTranslations from '../src/locale/ms/index';
import zhTranslations from '../src/locale/zh/index';

const resources = {
	en: {
		translation: {
			...enTranslations
		}
	},
	ms: {
		translation: {
			...msTranslations
		}
	},
	zh: {
		translation: {
			...zhTranslations
		}
	}
};

const options = {
	order: ['path', 'navigator'],
	lookupFromPathIndex: 0
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: options,
		resources,
		fallbackLng: 'en',
		supportedLngs: ['en', 'ms', 'zh'],
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
