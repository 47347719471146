export const PATHS_BY_ENV: any = {
	dev: {
		transferLink: 'https://mmt.staging.moneymatch.technology',
		transferLinkAU: 'http://mmt.staging.au.moneymatch.technology',
		transferLinkBN: 'http://mmt.staging.bn.moneymatch.technology',
		transferLinkNZ: 'http://mmt.staging.nz.moneymatch.technology',
		transferLinkSG: '',
		transferLinkHK: '',
		transferBusinessLink: 'https://moneymatch.technology/business',
		insuranceLink: 'https://insurance.moneymatch.technology/',
		loginLink: 'https://moneymatch.technology/region/login',
		signupLink: 'https://moneymatch.technology/region/register',
		promotionsLink: 'https://moneymatch.technology/promotions',
		aboutLink: 'https://moneymatch.technology/about-us',
		careersLink: 'https://moneymatch.technology/careers',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		partnerSupportCenterLink: 'https://partnersupport.moneymatch.co/hc/en-us',
		blogLink: 'https://blog.moneymatch.co/',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.technology/contact',
		newsLink: 'https://moneymatch.technology/newsroom',
		legalLink: 'https://moneymatch.technology/legal',
		IFSCLink: 'https://ifsc.moneymatch.technology/',
		BEFTNLink: 'https://beftn.moneymatch.technology/',
		personalTransfer: 'https://moneymatch.technology/transfers',
		businessTransfer: 'https://moneymatch.technology/business',
		enterpriseLink: 'https://moneymatch.technology/enterprise',
		agentsLink: 'https://moneymatch.technology/agents'
	},
	staging: {
		transferLink: 'https://mmt.staging.moneymatch.technology',
		transferLinkAU: 'http://mmt.staging.au.moneymatch.technology',
		transferLinkBN: 'http://mmt.staging.bn.moneymatch.technology',
		transferLinkNZ: 'http://mmt.staging.nz.moneymatch.technology',
		transferLinkSG: '',
		transferLinkHK: '',
		transferBusinessLink: 'https://moneymatch.technology/business',
		loginLink: 'https://moneymatch.technology/region/login',
		signupLink: 'https://moneymatch.technology/region/register',
		promotionsLink: 'https://moneymatch.technology/promotions',
		aboutLink: 'https://moneymatch.technology/about-us',
		careersLink: 'https://moneymatch.technology/careers',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		partnerSupportCenterLink: 'https://partnersupport.moneymatch.co/hc/en-us',
		blogLink: 'https://blog.moneymatch.co/',
		insuranceLink: 'https://insurance.moneymatch.technology/',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.technology/contact',
		newsLink: 'https://moneymatch.technology/newsroom',
		legalLink: 'https://moneymatch.technology/legal',
		IFSCLink: 'https://ifsc.moneymatch.technology/',
		BEFTNLink: 'https://beftn.moneymatch.technology/',
		personalTransfer: 'https://moneymatch.technology/transfers',
		businessTransfer: 'https://moneymatch.technology/business',
		enterpriseLink: 'https://moneymatch.technology/enterprise',
		agentsLink: 'https://moneymatch.technology/agents'
	},
	production: {
		transferLink: 'https://transfer.moneymatch.co',
		transferLinkAU: 'https://moneymatch.com.au',
		transferLinkBN: 'https://moneymatch.com.bn',
		transferLinkNZ: 'https://moneymatch.co.nz',
		transferLinkSG: '',
		transferLinkHK: '',
		transferBusinessLink: 'https://moneymatch.technology/business',
		insuranceLink: 'https://insurance.moneymatch.co/',
		supportCenterLink: 'https://support.moneymatch.co/hc/en-us',
		partnerSupportCenterLink: 'https://partnersupport.moneymatch.co/hc/en-us',
		loginLink: 'https://moneymatch.co/region/login',
		signupLink: 'https://moneymatch.co/region/register',
		promotionsLink: 'https://moneymatch.co/promotions',
		aboutLink: 'https://moneymatch.co/about-us',
		blogLink: 'https://blog.moneymatch.co/',
		careersLink: 'https://moneymatch.co/careers',
		privacyLink: 'https://transfer.moneymatch.co/privacy-policy/en',
		personalInsLink: 'https://getprotectionbymoneymatch.gr8.com/',
		referralProgramLink: 'https://support.moneymatch.co/hc/en-us/sections/900000189766-Referral-Program',
		remitFXLink: 'https://remitfx.moneymatch.co',
		apiRefLink: 'https://remitfx.moneymatch.co/api/documentation#/',
		contactUsLink: 'https://moneymatch.co/contact',
		newsLink: 'https://moneymatch.co/newsroom',
		legalLink: 'https://moneymatch.co/legal',
		IFSCLink: 'https://ifsc.moneymatch.co/',
		BEFTNLink: 'https://beftn.moneymatch.co/',
		personalTransfer: 'https://moneymatch.co/transfers',
		businessTransfer: 'https://moneymatch.co/business',
		enterpriseLink: 'https://moneymatch.co/enterprise',
		agentsLink: 'https://moneymatch.co/agents'
	}
};
