import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const mmtGradientPurple = 'radial-gradient(100% 730.99% at 0% 24.86%, #4568DC 0.49%, #B06AB3 92.48%)';
export const mmtLinearGradientPurple = 'linear-gradient(180deg, #4568DC 0%, #B06AB3 100%)';
export const mmtGradientYellow = 'linear-gradient(180deg, #F7E21E 0%, rgba(247,226,30,0.5) 46.7%, rgba(242,242,242,0.5) 74.83%, #F2F2F2 100%)';
export const mmtGradientGrey = 'linear-gradient(180deg, rgba(255,255,255,0) 0%, #F2F2F2 77.95%)';
export const mmtLinearGradientWhite = 'linear-gradient(270.08deg, #F2F2F2 1.45%, #FAFAFA 40.05%, #FFFFFF 99.93%)';
export const mmtGreyLinear2 = 'linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 100%)';

export const useCommonStyles = makeStyles<Theme, any>((theme) => ({
	mmtButtonPurple: {
		'&.MuiButton-root': {
			color: 'white',
			height: 56,
			background: mmtGradientPurple,
			borderRadius: 8
		},
		'&.MuiButton-root:hover': {
			background: mmtGradientPurple,
			cursor: 'pointer'
		},
		'&.MuiButton-root.Mui-disabled': {
			color: 'white'
		}
	},
	mmtBlackButton: {
		'&.MuiButton-root': {
			height: 56,
			color: 'white',
			borderRadius: 8,
			background: theme.palette.accent.main
		},
		'&.MuiButton-root:hover': {
			background: theme.palette.accent.main,
			cursor: 'pointer'
		}
	},
	mmtOutlineBlackButton: {
		'&.MuiButton-root': {
			padding: 16,
			height: 56,
			background: 'none',
			borderRadius: 8,
			color: theme.palette.accent.main,
			border: `2px solid ${theme.palette.accent.main}`
		},
		'&.MuiButton-root:hover': {
			background: 'none',
			cursor: 'pointer'
		}
	},
	mmtWhiteButton: {
		height: 56,
		background: mmtLinearGradientWhite,
		color: theme.palette.accent.main,
		borderRadius: 8,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	mmtLandingH1: ({ isMobile }) => ({
		fontFamily: 'Poppins',
		fontSize: 64,
		fontWeight: 700,
		lineHeight: isMobile ? '64px' : '96px',
		letterSpacing: '0em'
	}),
	mmtLandingH2: {
		fontFamily: 'Poppins',
		fontSize: 32,
		fontWeight: 700,
		lineHeight: '48px',
		letterSpacing: '0em'
	},
	mmtLandingH3: {
		fontFamily: 'Poppins',
		fontSize: 18,
		fontWeight: 400,
		lineHeight: '27px',
		letterSpacing: '0em'
	}
}));
